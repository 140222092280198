@import './mixin.scss';
@import './variables.scss';

.General_Header{
    width: 100%;
    height: 67px;
    background-color: $white_color;
    @include Optionflex(center,flex-end);
    gap: 1rem;
    border-bottom: 1px solid $home_bg;
    padding-inline: 1rem;
    position: fixed;
    top: 0px;
    right: 0px;
    backface-visibility: hidden;
    backdrop-filter: blur(400);
    z-index: 999;
    svg{
        width:25px;
        height:25px;
        color:$gray_color;
    }
    .MenuBar{
        display: none;
    }
    .Input_Wrapper{
        width: 308.47px;
        height: 38.67px;
        @include flex;
        gap: 1rem;
        input{
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            text-transform: capitalize;
            &::placeholder{
                @include PlaceholderText;
                color: $placeholder_color;
            }
        }
    }

    .Header_User{
    width: 57.12px;
    height: 31.64px;
     @include flex;
     gap: .5rem;
     .Avatar{
        height: 31.64px;
        aspect-ratio: 1;
        border-radius: 100%;
        @include Bg;
        background-image: url("../img/avatar.jpeg");
     }
     svg{
        width: 16px;
        height: 16px;
     }
    }
}


@media (max-width:768px){
    .General_Header{
        .MenuBar{
            display: inline-block;
        }
        .Input_Wrapper{
           display: none;
        }

    }   
}