@import './mixin.scss';
@import './variables.scss';

.Navigate_Btn{
    @include Optionflex(center,flex-end);
    padding-inline: 1.5rem;
    gap: .5rem;
    margin:1.5rem 0rem;
    margin-bottom: 5rem;
    .Nav_Btn{
        @include flex;
        width: 43.55px;
        height: 43.55px;
        border-radius: 100%;
        background-color: $arrow_btn_color;
        border: none;
        &:hover{
            border:1px solid $primary_color;
        }
    }
}