@import './mixin.scss';
@import './variables.scss';


.Wallet_Modal_Wrapper{
    width:540px;
    height: 100%;
    box-sizing: border-box;
    padding-top: 2rem;
    .close_btn{
        position: absolute;
        right:1rem;
        top:1rem;
    }
    .Tab_Wrapper{
        width: 100%;
        @include Optionflex(center,flex-start);
        gap:.3rem;
        .Tab{
            width: 160px;
            box-sizing: border-box;
            height:60px;
            border-radius: 3px;
            background-color:$sidebar_color;
            color: $dark;
            @include WalletTabText;
            @include flex;
            &.active_tab{
                background-color: $checkbox_border;
                color:$white_color;
            }
        }
    }
    .Details_Wrapper{
        width:100%;
        height: auto;
        border-radius: 3px;
        background-color:$sidebar_color;
        box-sizing: border-box;
        padding:3.5rem 0rem;
        @include flex;
        flex-direction: column;
        &.Amount{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding-inline:3rem;
            .Btn{
                @include ModalBtnDanger;
                @include ModalBtnText;
                background-color: $secondary_color;
                color: $white_color;
                cursor: pointer;
            }
            label {
                @include ModalAmount;
                color: $dark;
                &.error{
                    color: $btn_cancel;
                }
              }
    
              input {
                width: 100%;
                height: 45.02px;
                box-sizing: border-box;
                border-radius: 2.6235px;
                background-color: $white_color !important;
                outline: none;
                border: 1px solid transparent;
                padding: .5rem 1rem;
                // @include label;
                // color: $black_color;
                font-size: 13px;
                //text-transform: capitalize;
                margin:1rem 0rem;
                font-weight: normal;
    
                &::placeholder {
                //   @include inputText;
                //   color: $grey_color;
                  font-size: 13px;
                }
            }
        }
        .Top{
            width: 80%;
            margin-inline: auto;
        }
        .Bottom{
            width:80%;
            margin-inline: auto;
            margin-top: 2rem;
            @include Optionflex(center,space-between);
        }
        .Box{
            p{
                @include WalletTransferText;
                color: $checkbox_border;
            }
            h5{
              

            }
        }
    }
}


.Wallet_Top{
    width:95%;
    margin-inline: auto;
    @include flex;
    gap: 1rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    .Left{
        width: 75%;
        @include Optionflex(center,flex-start);
        gap: 1rem;
    }
    .Right{
        width: 25%;
        @include Optionflex(center,space-between);
        flex-direction: column;
        gap: 1rem;
        .Wallet_Btn{
            width:100%;
            height: 50px;
            border-radius: 9px;
            @include WalletBtnText;
            @include flex;
            cursor: pointer;
            &.Gray{
                color: $dark;
                border: 1px solid $input_border;
            }
            &.Danger{
                color: $white_color;
                background-color:$btn_cancel;
            }
        }
    }
    border-bottom: 1px solid $hero_shadow;
}

.Wallet_Header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-bottom: 1.5rem;
    width: 95%;
    margin-inline: auto;
    &.activate_fade{
        opacity:.1;
    }
    h3{
        @include subShipmentsTitle;
        color: $dark;
    }
    .Right{
        @include flex;
        gap:.5rem;
        select{
            width: 180px;
            height: 35px;
            border-radius: 2.6235px;
            border: 1px solid $input_border;
            box-sizing: border-box;
            // padding:1rem .3rem;
            text-transform: capitalize;
            outline: none;
            @include selectedInputText;
            color:$light_gray;
            padding-left: 1rem;
        }

        input{
            width: 180px;
            height: 35px;
            padding-left: 1rem;
            border-radius: 2.6235px;
            border: 1px solid $input_border;
            box-sizing: border-box;
            // padding:1rem .3rem;
            text-transform: capitalize;
            outline: none;
            @include selectedInputText;
            color:$light_gray;
        }
        .Search_Box{
            background-color: $primary_color;
            @include flex;
            width: 34.99px;
            height: 35px;
            border-radius: 2px;
        }
    }
}

.Wallet_Transaction{
    width:95%;
    margin-inline: auto;
    table{
        width: 100%;
        border-collapse: collapse;
        thead{
            width: 100%;
            th{
                width: calc(100%/3.3);
                @include ShipmentsTableHeader;
                @include DMSan_Family;
                color:$checkbox_border;
                padding: 1rem 0rem;
                border-bottom: 1px solid $hero_shadow;
            }
        }
        tbody{
            width: 100%;
            tr{
                td{
                    border-bottom: 1px solid $hero_shadow;
                    .No_Data{
                        width: 100%;
                        text-align: center;
                        padding:4rem 0rem;
                        h5{
                            @include ShipmentsTableBodyTitle;
                            @include DMSan_Family;
                            color: $dark; 
                        }
                    } 
                    .Box{
                        width:150px;
                        height:65px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        margin:.5rem 0rem;
                        overflow: hidden;
                        h5{
                            @include ShipmentsTableBodyTitle;
                            @include DMSan_Family;
                            color: $dark; 
                        }
                        p{
                            @include AllShipmentsTableText;
                            @include DMSan_Family;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            color:$checkbox_border;
                        }
                        .Btn{
                            width: 100px;
                            height: 26.71px;
                            border-radius: 1.44093px;
                            @include  ShipmentBtnText;
                            @include flex;
                            &.success{
                                background-color:$btn_bg_success;
                                color: $btn_success;
                            }
                            &.cancel{
                                background-color:$btn_bg_cancel;
                                color:$btn_cancel;
                            }
                            &.active{
                                background-color:$btn_bg_active;
                                color:$btn_active;
                            }
                            &.pending{
                                background-color:$btn_bg_pending;
                                color:$btn_pending;
                            }
                        }
                    } 
                }
            }
        }
    }
}

.Empty_Wallet{
    @include flex;
    box-sizing: border-box;
    flex-direction: column;
    padding:6rem  0rem;
    h3{
        @include WalletEmptyTitle;
        color: $dark;
    }
    p{
        @include WalletEmptyText;
        color: $checkbox_border;
    }
}

.Spinner_Div{
    width: 100%;
    height: 50px;
    @include flex;
}


@media (max-width:768px){
    .Wallet_Top{
        .Left{
            width: 85%;
        }
        .Right{
            width: 15%;
        }
    }
    .Wallet_Transaction{
        table{
            thead{
                th{
                }
            }
            tbody{
                tr{
                    td{
                        .Box{
                            width: 115px;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width:400px){
    .Wallet_Top{
        flex-direction: column;
        .Left{
            width:100%;
            flex-direction: column;
        }
        .Right{
            width:100%;
            gap: 1rem;
        }
    }

    .Wallet_Header{
        flex-direction: column;
        @include Optionflex(flex-start,center);
        .Right{
            width: 100%;
            flex-direction: column;
            @include Optionflex(flex-start,center);
           select{
            width: 100%;
           }
           input{
            width: 100%;
           }
        }
    }

    .Wallet_Transaction{
        table{
            thead{
                width: 100%;
                th{
                    width: 100%;
                    display: block;
                }
            }
            tbody{
                width: 100%;
                tr{
                    td{
                        width: 100%;
                        display: block;
                        .Box{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .Wallet_Modal_Wrapper{
        width:330px;
        .Tab_Wrapper{
            .Tab{
                width:49%;
               
            }
        }
        .Details_Wrapper{
            &.Amount{
                padding-inline:2rem;
            }
            .Bottom{
                width:80%;
                flex-direction: column;
                @include Optionflex(flex-start,space-between);
                gap: 2rem;
            }
        }
    }
}