@font-face {
    font-family: 'Coolvetica Lt';
    src: url('CoolveticaLt-Regular.eot');
    src: local('CoolveticaLt-Regular'),
        url('CoolveticaLt-Regular.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaLt-Regular.woff2') format('woff2'),
        url('CoolveticaLt-Regular.woff') format('woff'),
        url('CoolveticaLt-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Coolvetica Bk';
    src: url('CoolveticaBk-Regular.eot');
    src: local('CoolveticaBk-Regular'),
        url('CoolveticaBk-Regular.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaBk-Regular.woff2') format('woff2'),
        url('CoolveticaBk-Regular.woff') format('woff'),
        url('CoolveticaBk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Coolvetica Rg';
    src: url('CoolveticaRg-BoldItalic.eot');
    src: local('CoolveticaRg-BoldItalic'),
        url('CoolveticaRg-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaRg-BoldItalic.woff2') format('woff2'),
        url('CoolveticaRg-BoldItalic.woff') format('woff'),
        url('CoolveticaRg-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Coolvetica Rg';
    src: url('CoolveticaRg-Regular.eot');
    src: local('CoolveticaRg-Regular'),
        url('CoolveticaRg-Regular.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaRg-Regular.woff2') format('woff2'),
        url('CoolveticaRg-Regular.woff') format('woff'),
        url('CoolveticaRg-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Coolvetica Ul';
    src: url('CoolveticaUl-Regular.eot');
    src: local('CoolveticaUl-Regular'),
        url('CoolveticaUl-Regular.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaUl-Regular.woff2') format('woff2'),
        url('CoolveticaUl-Regular.woff') format('woff'),
        url('CoolveticaUl-Regular.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Coolvetica Hv';
    src: url('CoolveticaHv-Regular.eot');
    src: local('CoolveticaHv-Regular'),
        url('CoolveticaHv-Regular.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaHv-Regular.woff2') format('woff2'),
        url('CoolveticaHv-Regular.woff') format('woff'),
        url('CoolveticaHv-Regular.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Coolvetica Rg';
    src: url('CoolveticaRg-Italic.eot');
    src: local('CoolveticaRg-Italic'),
        url('CoolveticaRg-Italic.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaRg-Italic.woff2') format('woff2'),
        url('CoolveticaRg-Italic.woff') format('woff'),
        url('CoolveticaRg-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Coolvetica El';
    src: url('CoolveticaEl-Regular.eot');
    src: local('CoolveticaEl-Regular'),
        url('CoolveticaEl-Regular.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaEl-Regular.woff2') format('woff2'),
        url('CoolveticaEl-Regular.woff') format('woff'),
        url('CoolveticaEl-Regular.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Coolvetica Bk';
    src: url('CoolveticaBk-Italic.eot');
    src: local('CoolveticaBk-Italic'),
        url('CoolveticaBk-Italic.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaBk-Italic.woff2') format('woff2'),
        url('CoolveticaBk-Italic.woff') format('woff'),
        url('CoolveticaBk-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Coolvetica Lt';
    src: url('CoolveticaLt-Italic.eot');
    src: local('CoolveticaLt-Italic'),
        url('CoolveticaLt-Italic.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaLt-Italic.woff2') format('woff2'),
        url('CoolveticaLt-Italic.woff') format('woff'),
        url('CoolveticaLt-Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Coolvetica Hv';
    src: url('CoolveticaHv-Italic.eot');
    src: local('CoolveticaHv-Italic'),
        url('CoolveticaHv-Italic.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaHv-Italic.woff2') format('woff2'),
        url('CoolveticaHv-Italic.woff') format('woff'),
        url('CoolveticaHv-Italic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Coolvetica Ul';
    src: url('CoolveticaUl-Italic.eot');
    src: local('CoolveticaUl-Italic'),
        url('CoolveticaUl-Italic.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaUl-Italic.woff2') format('woff2'),
        url('CoolveticaUl-Italic.woff') format('woff'),
        url('CoolveticaUl-Italic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Coolvetica El';
    src: url('CoolveticaEl-Italic.eot');
    src: local('CoolveticaEl-Italic'),
        url('CoolveticaEl-Italic.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaEl-Italic.woff2') format('woff2'),
        url('CoolveticaEl-Italic.woff') format('woff'),
        url('CoolveticaEl-Italic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Coolvetica Rg';
    src: url('CoolveticaRg-Bold.eot');
    src: local('CoolveticaRg-Bold'),
        url('CoolveticaRg-Bold.eot?#iefix') format('embedded-opentype'),
        url('CoolveticaRg-Bold.woff2') format('woff2'),
        url('CoolveticaRg-Bold.woff') format('woff'),
        url('CoolveticaRg-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

