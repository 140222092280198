@import './mixin.scss';
@import './variables.scss';


.Home_Wrapper{
    background-color: $home_bg;
}

.Header{
    height: 67px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .Inner-Container{
        width: 90%;
        height: 100%;
        margin-inline: auto;
    @include Optionflex(center,space-between);
    .Logo{
        width: 75.37px;
        height: 47.6px;
        background-image: url("../img/Ella.png");
        @include Bg;
    }
    .Right{
        @include Optionflex(center,space-between);
        ul{
            display: flex;
            align-items: center;
            margin-right: 2rem;
            li{
                margin-inline: 1rem;
                a{
                    @include LandingBtnText;
                    text-transform: capitalize;
                    color: $gray_color;
                    &.active-link{
                        color: $secondary_color;
                    }
                }
            }
        }
    }
    svg{
        display: none;
        width: 40px;
        height: 40px;
    }
    }
}
.Sidebar_Content{
    width: 100%;
    height: 100vh;
    .Inner-Container{
        width: 90%;
        height: 100%;
        margin-inline: auto;
    @include Optionflex(flex-start,flex-start);
    flex-direction: column;
    gap: 2rem;
    .Logo{
        width: 75.37px;
        height: 47.6px;
        background-image: url("../img/Ella.png");
        @include Bg;
        margin-top:1rem;
    }
    .Right{
        @include Optionflex(flex-start,space-between);
        flex-direction: column;
        ul{
            display: flex;
            align-items:flex-start;
            flex-direction: column;
            margin-bottom: 2rem;
            
            li{
                margin:.5rem 1rem;
                a{
                    @include LandingBtnText;
                    text-transform: capitalize;
                    color: $gray_color;
                    &.active-link{
                        color: $secondary_color;
                    }
                }
            }
        }
        .Home_Btn{
            margin-left:1rem!important;
        }
    }
    }
}

.Hero_Section{
    width: 100%;
    height: auto;
    // margin-inline: auto;
    padding: 3rem 0 5rem 0;
    background: linear-gradient(to right, 
    rgba(255, 0, 0, 0.1) 0%,       /* Red with some transparency */
    rgba(255, 255, 255, 0.5) 50%,  /* White with some transparency */
    rgba(0, 0, 255, 0.1) 100%      /* Blue with some transparency */
    );
    @include flex;
    justify-content: space-between;
    // margin-top:3rem;
    .Left{
        padding-left: 5%;
        h2{
            @include  HeroTitle;
            color: $primary_color;
        }
        p{
         @include  HeroText;
         color: $gray_color;
         margin:2rem 0rem;
        }
    }
    .Right{
        position: relative;
        width: 50%;
        height: 637px;
        overflow: hidden;
        .Circle_Bg{
            width: 80%;
            height:614px;
            // background-color: $secondary_color;
            border-radius: 100%;
            position: absolute;
            left: 50%;
            bottom: 0px;
            transform: translateX(-50%);
            // overflow: hidden;
            
            .Orbit_Line {
                width: 400px;
                height: 400px;
                background-color: transparent;
                border-radius: 100%;
                border: 1px solid gray;
                position: absolute;
                left: 22%;
                top: 16%;
            }
            .Image{
                width: 500px;
                height: 630px;
                @include Bg;
                background-image: url("../img/DesktopLanding.png");
                background-size: contain;
                position: absolute;
                left: 50%;
                bottom: 0px;
                transform: translateX(-50%);
            }
        }
        .Top,.Bottom{
            @include Bg;
            width: 200px;
            height: 364px;
            position: absolute;
            display: none;
        }
        .Top{
            background-image: url("../img/EllaMobile.png");
            background-size: contain;
            left: 6rem;
            top: 14%;
        }
        .Bottom{
            background-image: url("../img/Card2.png");
            right: 0px;
            bottom: 0%;
            box-shadow: 0px 38.7755px 77.551px $hero_shadow;
        }
    }
}

.Logos_Wrapper{
    width: 100%;
    height:300px;
    @include flex;
    gap:10rem;
    margin-top:3rem;
    .img{
        @include Bg;
        &:nth-of-type(1){
            width: 142.23px;
            height: 23.85px;
            background-image: url("../img/dhl.png");
        }
        // &:nth-of-type(2){
        //     width: 156.41px;
        //     height: 37.22px;
        //     background-image: url("../img/topship.png");
        // }
        &:nth-of-type(2){
            width: 99.88px;
            height: 30.16px;
            background-image: url("../img/FedEx.png");
        }
        &:nth-of-type(3){
            width: 64.3px;
            height: 60.29px;
            background-image: url("../img/UPS-logo.png");
        }
        
    }
}

.WareHouse_Wrapper{
    width: 100%;
    height:45rem;
    @include flex;
    flex-direction: column;
    overflow: hidden;
    h3{
        @include WarehouseTitle;
        text-align: center;
        color: $little_deep_primary;
    }
    padding-bottom: 5rem;
    .Wrapper{
        @include flex;
        gap: 2rem;
        margin-top:5rem;
      .Image_Card{
        width:340px;
        height: 444px;
        background-color: $white_color;
        box-shadow:  0px 38.7755px 77.551px $hero_shadow;
        &:nth-of-type(1){
            .Image{
                background-image: url("../img/air.jpeg");
            }
        }
        &:nth-of-type(2){
            .Image{
                background-image: url("../img/ocean.jpeg");
            }
        }
        &:nth-of-type(3){
            .Image{
                background-image: url("../img/land.jpeg");
            }
        }
        .Image{
            width: 100%;
            height: 240px;
            background-color: $image_gray;
            @include Bg;
        }
        .Wrap{
            width: 100%;
            padding:2.5rem 2rem;
            h5{
             @include WarehouseCardTitle;
             color: $primary_color;
            }
            p{
                @include WarehouseCardText;
                color: $gray_color;
                margin-top: 1rem;
            }
        }
      }  
    }
}

.Clients_Banner{
    width: 100%;
    height: 622px;
    position: relative;
    background-color: $primary_color;
    margin: 3rem 0rem;
    overflow: hidden;
    .Img1,.Img2,.Img3,.Img4{
        @include Bg;
        width: 150px;
        height: 150px;
        position: absolute;
    }
    .Img1{
        background-image: url("../img/img1.png");
        top: 10%;
        left: 15%;
    }
    .Img2{
        background-image: url("../img/img2.png");
        left: 20%;
        bottom:10%;
    }
    .Img3{
        background-image: url("../img/img3.png");
        top: 10%;
        right: 15%;
    }
    .Img4{
        background-image: url("../img/img4.png");
        right: 20%;
        bottom:10%;
    }
    .Middle_Box{
        @include flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        h5{
            @include WarehouseTitle;
            color: $white_color;
            text-align: center;
        }
        p{
            @include MiddleQouteText;
            text-align: center;
            color: $pink;
            margin-top: 2rem;
            margin-bottom: 1rem;
        }
        .Wrap{
            display: flex;
            align-items: center;
            .Avatar{
                width: 50px;
                height: 50px;
                border-radius: 100%;
                @include Bg;
                background-image: url("../img/Ellipse.png");
                margin-right: .5rem;
            }
            .Right{
               
                h6{
                    @include  MiddleQouteTinyTitle;
                    color: $white_color;
                }
                p{
                    @include  MiddleQouteTinyText;
                    color: $home_bg;
                    margin: 0px;
                    text-align: left;
                }
            }
        }
    }
}

.Service{
    width: 100%;
    height: auto;
    padding:8rem 0rem;
    h3{
        @include WarehouseTitle;
        color: $little_deep_primary;
        text-align: center;
    }
    .Wrapper{
        margin-top:5rem;
        @include flex;
        gap: 2rem;
        flex-wrap: wrap;
        .Service_Card{
            width: 340px;
            height: 380px;
            padding:2.5rem;
            background-color: $white_color;
            box-shadow: 0px 38.7755px 77.551px $hero_shadow;
            h5{
              @include WarehouseCardTitle;
              color: $little_deep_primary;
              margin:1.5rem 0rem;  
            }
            p{
                @include ServiceCartText;
                color: $gray_color;
            }
            .Img{
                width: 80px;
                height: 80px;
                border-radius: 100%;
                @include flex;
                background-color: $light_pink;
            }
        }
    }
}

.Iphone_Section{
    width: 100%;
    height:550px;
    margin-top:5rem;
    position: relative;
    overflow: hidden;
    .Phone{
        width: 500px;
        height: 500px;
        @include Bg;
        background-image: url("../img/phone.png");
        position: absolute;
        bottom:7%;
        left: 50%;
        transform: translateX(-50%);
    }
    .Top_Card{
        position: absolute;
        width: 190px;
        height: 193px;
        background-color: $white_color;
        box-shadow: 0px 38.7755px 77.551px $hero_shadow;
        border-radius: 30px;
        top: 0%;
        left: 30%;
        @include flex;
        flex-direction: column;
        .Img{
            width: 62.82px;
            height: 62.82px;
            border-radius: 22.1705px;
            @include Bg;
            background-image: url("../img/thumbup.png");
            margin-bottom: 1.5rem;
        }
        .App{
            width: 116.54px;
            height: 40.15px;
            @include Bg;
            border-radius: 3px;
            background-image: url("../svg/Googlestore.svg");
        }
    }
    .Bottom_Card{
        position: absolute;
        width: 190px;
        height: 193px;
        background-color: $white_color;
        box-shadow: 0px 38.7755px 77.551px $hero_shadow;
        border-radius: 30px;
        bottom: 0%;
        right: 30%;
        @include flex;
        flex-direction: column;
        .Img{
            width: 62.82px;
            height: 62.82px;
            border-radius: 22.1705px;
            @include Bg;
            background-image: url("../img/thumbup.png");
            margin-bottom: 1.5rem;
        }
        .App{
            width: 116.54px;
            height: 40.15px;
            @include Bg;
            border-radius: 3px;
            background-image: url("../svg/Appstore.svg");
        }
    }
}


.FAQ{
    width: 100%;
    height: 500px;
    margin-top:0rem;
    background-color: $secondary_color;
    @include flex;
    flex-direction: column;
    overflow: hidden;
    h3{
        @include WarehouseTitle;
        color: $white_color;
    }
    p{
        @include HeroText;
        color: $white_color;
        margin-top: 1.5rem;
        margin-bottom:4rem;
    }
    .Wrap{
        @include Optionflex(center,flex-start);
        gap: 1rem;
        .Input_Wrap{
            width: 393px;
            height: 80px;
            overflow: hidden;
            @include Optionflex(center,flex-start);
         .Icon{
          width: 60px;
          height: 100%;
          @include Optionflex(center,flex-end);
          background-color: $white_color;
          svg{
            color: $little_deep_primary;
          }
         }
         input{
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            padding:1rem .5rem;
         }
        }
        .Btn{
            width: 198px;
            height: 80px;
            padding: 15px 37px;
            color: $white_color;
            background-color: $primary_color;
            text-transform: uppercase;
            cursor: pointer;
            @include LandingBtnText;
            @include flex;
        }
    }
}

.Footer{
    width: 100%;
    height: 521px;
    background-color: $primary_color;
    color: $white_color;
    @include flex;
    flex-direction: column;
    gap: 8rem;
   .Container{
    width: 90%;
    @include Optionflex(flex-start,space-start);
    gap: 8rem;
    ul{
        // width: 72.02px;
        &:nth-of-type(1){
            width: 374.94px;
        }
        &:nth-of-type(2){
            width: 172.6px;
        }
        li{
            @include FooterText;
            a{
                color: $white_color;
            }
            .Logo{
                width: 75.38px;
                height: 46.72px;
                @include Bg;
                background-image: url("../img/Ellawhite.png");
                margin-bottom:1rem;
            }
            &.Title{
                @include FooterTitle;
                margin-bottom:1rem;
                white-space:pre;
            }
        }
    }
   }
   .Bottom{
    width: 90%;
    border-top: 1px solid $footer_border_color;
    @include Optionflex(flex-start,space-between);
    padding-top: 2rem;
    p{
        @include FooterTiny;
    }
    ul{
        li{
            @include FooterTiny; 
        }
    }
   }
}


@media (max-width:768px){
    .Header{
        position: relative;
        height: auto;
        padding:1.2rem 1rem;
        border-bottom: 1px solid $border_bottom_color;
        .Inner-Container{
            width: 95%;
        }
    }
    .Hero_Section{
        width: 95%;
        height:100vh;
        background: #ffffff;
        margin-inline: auto;
        padding: 0;
        flex-direction: column-reverse;
        margin-top:2rem;
        .Left{
            width: 100%;
            margin-top: 2rem;
        }
        .Right{
            width:100%;
            height:75vh;
            .Circle_Bg{
                width: 550px;
                height:550px;
                .Orbit_Line {
                    display: none;
                }
                .Image{
                    background-image: url("../img/MobileLanding.png");
                    width: 450px;
                    height:500px;
                }
            }
            .Top{
                left: 45px;
            }
        }
    } 
    
    .Logos_Wrapper{
        height:200px;
        gap:5rem;
        margin-top: 0rem;
    }

    .WareHouse_Wrapper{
        width: 100%;
        height:auto;
        margin-top: 3rem;
        .Wrapper{
            flex-wrap: wrap;
          .Image_Card{
            width:calc(100%/2.5);
          }  
        }
    }
    .Clients_Banner{
        width: 100%;
        height: 622px;
        .Img1{
            left: 0%;
            top:2%;
        }
        .Img2{
            left: 10%;
            bottom:10%;
        }
        .Img3{
            right: 0%;
            top:2%;
        }
        .Img4{
            right: 10%;
            bottom:10%;
        }
        .Middle_Box{
            width: 90%;
        }
    }

    .Service{
        padding:4.5rem 0rem;
    }

    .Iphone_Section{
        .Top_Card{
            top: 0%;
            left: 15%;
        }
        .Bottom_Card{
            bottom: 0%;
            right: 15%;
            
        }
    }

    .Footer{
       .Container{
        gap: 2rem;
        ul{
            width: 100px;
        }
       }
    }

}

@media (max-width:400px){
    .Header{
        .Inner-Container{
            width: 95%;
            // .Right{
            //     display: none;
            // }
            // svg{
            //     display: block;
            //     size:5rem;
            // }
        }
    }
    .Hero_Section{
        width: 95%;
        height:auto;
        margin-top:1rem;
        .Left{
            h2{
                @include  HeroTitleMobile;
            }
        }
        .Right{
            height:50vh;
            .Circle_Bg{
                width: 350px;
                height:360px;
                .Image{
                    width: 300px;
                    height:360px;
                }
            }
            .Top{
                left: 20px;
                width: 120px;
                height: 230px;
            }
            .Bottom{
                right: -35px;
                bottom: -55px;
            }
        }
    } 
    .Logos_Wrapper{
        height:200px;
        gap:1rem;
        .img{
            width: 85px!important;
            height: 20px!important;
            &:nth-of-type(4){
                height: 60px!important;
            }
        }
        // flex-direction: column;
    }
    .WareHouse_Wrapper{
        width: 95%;
        height:auto;
        margin-top: 3rem;
        margin-inline: auto;
        h3{
            @include WarehouseTitleMobile;
        }
        .Wrapper{
            flex-direction: column;
          .Image_Card{
            width:100%;
          }  
        }
    }
    .Clients_Banner{
        height:500px;
        .Img1{
            top: 0%;
            left: 0%;
        }
        .Img2{
            left: 0%;
            bottom:0%;
        }
        .Img3{
            top: 0%;
            right: 0%;
        }
        .Img4{
            right:0%;
            bottom:0%;
        }
        .Middle_Box{
            width: 100%;
            h5{
                @include WarehouseTitleMobile;
            }
            p{
                @include MiddleQouteTextMobile;
            }
        }
       
    }
    .Service{
        h3{
            @include WarehouseTitleMobile;
        }
    }
    .Iphone_Section{
        height:400px;
        margin-top:3.5rem;
        .Phone{
            width: 350px;
            height:350px;
        }
        .Top_Card{
            width: 160px;
            height: 160px;
            border-radius:10px;
            top: 0%;
            left: 0%;
        }
        .Bottom_Card{
            width: 160px;
            height: 160px;
            border-radius:10px;
            bottom: 0%;
            right: 0%;
        }
    }
    .FAQ{
        margin-top:8rem;
        padding-inline:1rem;
        text-align: center;
        h3{
            @include WarehouseTitleMobile;
        }
        .Wrap{
            width: 100%;
            flex-direction: column;
            .Input_Wrap{
                width: 100%;
                .Icon{
                   @include Optionflex(center,center);
                }
                input{
                    margin-left: -.3rem;
                }
            }
            .Btn{
                width:100%;
            }
        }
    }
    .Footer{
        height: auto;
        padding: 3rem 0rem;
        gap: 4rem;
       .Container{
        width: 95%;
       flex-wrap: wrap;
        gap: 1.5rem;
        ul{
            width:100%;
        }
       }
       .Bottom{
        width: 95%;
        margin-inline: auto;
        flex-direction: column;
        gap: 1rem;
       }
    }
}

.TRACK {
    width: 100%;
    height: 580px;
    margin-top:0rem;
    background-color: $secondary_color;
    @include flex;
    flex-direction: column;
    overflow: hidden;
    color: $white_color;

    h3{
        font-size: 40px;
    }
    .Wrap{
        width: 100%;
        flex-direction: column;
        .Input_Wrap{
            width: 100%;
            .Icon{
               @include Optionflex(center,center);
            }
            input{
                margin-left: -.3rem;
            }
        }
        .Btn{
            width:100%;
        }
    }
}

.Track_Wrap {
    background-color: #ffffff;
    width: 760px;
    border-radius: 10px;
    padding: 2rem 2.5rem;

    .track_input {
        width: 100%;
        height: 50px;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid $svg_color;
    }

    .track_btn {
        background-color: $btn_cancel;
        color: $white_color;
        width: 140px;
        border-radius: 10px;
        border: none;

        &:focus {
            outline: none;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        width: 100%;

        li {
            padding-bottom: 1.2rem;
            display: flex;
            gap: 1.4rem;
            width: 100%;
            
            p {
                color: $gray_color;
                margin: 0;
                font-size: 14px;
            }
        }
    }

    .track_line {
        position: absolute;
        height: 100%;
        left: 15px;
        width: .5px;
        background-color: $btn_cancel;
    }

    .track_info {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .track_circle_active, .track_circle_inactive {
        width: 34px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        position: relative;
        z-index: 2;
    }

    .track_circle_active {
        background-color: $btn_cancel;
        color: $white_color;
        border: 2px solid $btn_cancel;
    }

    .track_circle_inactive {
        background-color: $white_color;
        color: $btn_cancel;
        border: 2px solid $btn_cancel;
    }
}

@media(max-width:600px) {
    .TRACK {
        text-align: center;
        padding: 1rem;
    }
    .Track_Wrap {
        width: 100%;
        padding: 1rem;
    }

    .track_input {
        height: 45px;
    }
}