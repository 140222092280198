$primary_color:#151A42;
$little_deep_primary:#292E47;


$light_success: #DEEDE5;
$deep_success:#427A5B;

$btn_bg_success:#CFEFE2;
$btn_success:#219653;

$btn_bg_cancel:#FDD9DD;
$btn_cancel:#E92D38;

$btn_bg_active:#CFEBFF;
$btn_active:#51A9F7;

$btn_bg_pending:#F6F0D1;
$btn_pending:#EAB761;

$dark:#000000;

$secondary_color:#ED0022;
$secondary_light:#FF000F;
$pink:#F3A2AE;
$login_Bg:#FDE3E7;
$sidebar_color:#FFEEF1;
$light_pink: rgba(237, 0, 34, 0.05);
$package_bg_color:#F9BEC3;

$gray_color:#6B708D;
$image_gray:#C4C4C4;
$input_border:#E0E0E0;
$checkbox_border:#828282;
$placeholder_color:#6D7D93;
$svg_color:#ABA1A3;
$anchor_link:#808080;
$dash_table_header_color:#4C4C4C;
$dash_table_body_color:#808080;
$light_gray:#4F4F4F;
$dash_color:#BDBDBD;
$svg_bg:#D3D3D3;
$tab_bg: #F8F8F8;



$white_color:#FFFFFF;
$home_bg:#F4F6FC;
$arrow_btn_color:#F2F2F2;

$border_bottom_color:rgb(236, 239, 241);
$hero_shadow:rgba(0, 0, 0, 0.1);
$dash_first_card_shadow:rgba(0, 0, 0, 0.25);
$footer_border_color:rgba(255, 255, 255, 0.25);
$grap_border:rgba(109, 125, 147, 0.15);
$dash_stroke:rgba(109, 125, 147, 0.5);
$add_Btn_Color:rgba(109, 125, 147, 0.4);
$setting_border:rgba(0, 0, 0, 0.05);
$dropdown_shadow:rgba(0, 0, 0, 0.2);

$grey: rgba(109, 125, 147, 0.5);
