@import './mixin.scss';
@import './variables.scss';


.Setting_Wrapper{
    width: 70%;
    margin-inline: auto;
    @include Optionflex(flex-start,center);
    margin-top: 6rem;
    margin-bottom:10rem;
    .Left{
        width: 25%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        .Box{
            width: 150px;
            h4{
              @include SettingProfileLabel;
              color: $dark; 
              margin-bottom: .6rem; 
            }
            Label{
                .Btn{
                    width: 150px;
                    height:40px;
                    border-radius: 3px;
                    @include flex;
                    color:$white_color;
                    background-color: $primary_color;
                    text-align: center;
                    margin-top: .5rem;
                    cursor: pointer;
                    @include ShipmentsTableHeader;
                }
                .Label_Box{
                    width: 161.2px;
                    height: 159.9px;
                    border: 1px dashed $dash_color;
                    border-radius: 3px;
                    @include flex;
                    flex-direction: column;
                    span{
                        @include FileText;
                        color: $dash_color;
                    }
                }
            }
            img{
                width:150px;
                aspect-ratio: 1;
                border-radius: 5px;
            }
        }
    }
    .Right{
        width: 68%;
        .Detail_Wrapper{
            width: 100%;
            .Box{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid $setting_border;
                padding:1rem 0rem;
                &:nth-of-type(1){
                    padding-top: 0rem;
                    .Left{
                        h4{
                            @include ShipmentsTableHeader;
                            color: $dark;
                        }
                    }
                }
                .Left{
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: row;
                    gap: .5rem;
                    h4{
                        @include FileText;
                        color: $dark;
                    }
                    .Btn{
                        width: 89.94px;
                        height: 25.74px;
                        border-radius: 3px;
                        background-color:$btn_cancel;
                        color: $white_color;
                        @include ShipmentsTableHeader;
                        @include flex;
                        cursor: pointer;
                    }
                }
                .Right{
                    display: flex;
                    align-items:flex-end;
                    justify-content: center;
                    flex-direction: column;
                    p{
                        @include FileText;
                        color:$checkbox_border;
                    }
                    .Btn{
                        width: 80.69px;
                        height: 25.74px;
                        border-radius: 3px;
                        background-color: $sidebar_color;
                        color: $dark;
                        @include ShipmentsTableHeader;
                        @include flex;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.Settin_Modal_Wrapper{
    width:400px;
    height: 100%;
    box-sizing: border-box;
    padding:2.5rem 2.5rem;
    .Details_Wrapper{
        width:100%;
        height: auto;
        border-radius: 3px;
        box-sizing: border-box;
        padding:0rem;
        @include flex;
        flex-direction: column;
        &.Amount{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding-inline:0rem;
            .Btn{
                @include ModalBtnDanger;
                @include ModalBtnText;
                background-color: $secondary_color;
                color: $white_color;
                cursor: pointer;
            }
            label {
                @include ModalAmount;
                color: color;
              }
    
              input {
                width: 100%;
                height: 45.16px;
                box-sizing: border-box;
                border-radius: 2.63164px;
                background-color:$sidebar_color;
                outline: none;
                border: 1px solid transparent;
                padding: .5rem 1rem;
                margin:1rem 0rem;
                font-weight: normal;
    
                &::placeholder {
                    @include settingModalPlaceholder;
                    color: $checkbox_border;
                }
            }
        }
        .Top{
            width: 80%;
            margin-inline: auto;
        }
        .Bottom{
            width:80%;
            margin-inline: auto;
            margin-top: 2rem;
            @include Optionflex(center,space-between);
        }
        .Box{
            p{
                @include WalletTransferText;
                color: $checkbox_border;
            }
            h5{
                // @include WalletTabHeader;

            }
        }
    } 
}

@media (max-width:768px){
    .Setting_Wrapper{
        width:95%;
        .Left{
            width: 25%;
            .Box{
                width: 150px;
                h4{
                  @include SettingProfileLabel;
                  color: $dark; 
                  margin-bottom: .6rem; 
                }
                Label{
                    .Btn{
                        width: 150px;
                        height:40px;
                    }
                    .Label_Box{
                        width: 161.2px;
                        height: 159.9px;
                    }
                }
                img{
                    width:150px;
                    aspect-ratio: 1;
                    border-radius: 5px;
                }
            }
        }
        .Right{
            width: 68%;
        }
    }  
}

@media (max-width:400px){
    .Setting_Wrapper{
        width:95%;
        flex-direction:column-reverse;
        .Left{
            width: 100%;
            align-items: flex-start;
        }
        .Right{
            width:100%;
            margin-top:2rem;
        }
    }
    .Settin_Modal_Wrapper{
        width:330px;
        height: 100%;
    }  
}