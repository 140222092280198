@import './mixin.scss';
@import './variables.scss';

.Login_Wrapper{
    width: 100%;
    height: 100vh;
    @include flex;
    .Left,.Right{
        width: 50%;
        height: 100%;
    }
    .Left{
        background-color: $login_Bg;
        @include Optionflex(center,flex-end);
        flex-direction: column;
        p{
        @include LoginTiny;
        color: $primary_color;
        text-align: center;
        margin-bottom: 1rem;
        }
        .Logo{
            width: 123.81px;
            height: 78.61px;
            @include Bg;
            background-image: url("../img/Ella.png");
            margin-bottom: 1.5rem;
        }
        .Transport{
            width: 100%;
            height: 400px;
            @include Bg;
            background-image: url("../img/login.png");
            background-color: $login_Bg;
            background-blend-mode:multiply;
        }
    }
    .Right{
        position: relative;
        .LogIn_Form{
            width: 70%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            .Wrapper{
                position: relative;
                .Logo{
                    width: 88.78px;
                    height: 56.37px;
                    @include Bg;
                    background-image: url("../img/Ella.png");
                    margin-bottom: 3rem;
                    &.Register{
                        margin-bottom: 1rem;
                    }
                }
                h4{
                    @include logInHelloText;
                    color: $primary_color;
                }
                p{
                    @include LoginText;
                    color: $checkbox_border;
                }
                form{
                    width: 100%;
                    .Top{
                        @include Optionflex(center,space-between);
                        gap: 1rem;
                        margin:1rem 0rem;
                        .Box{
                            width: 100%;
                            margin:0rem!important;
                        }
                    }
                   .Box{
                    &.Email_Wrap{
                        margin:1rem 0rem;
                    }
                    &.Reset_Email{
                        margin-bottom:2rem!important;
                    }
                    &:nth-of-type(2){
                        margin-bottom: .5rem;
                    }
                    &:nth-of-type(1){
                        margin:2rem 0rem;
                    }
                    input{
                        text-transform: capitalize;
                        width: 100%;
                        height: 44.86px;
                        border-radius: 3.71471px;
                        border:1.23824px solid  $input_border;
                        padding:1rem .5rem;
                        outline: none;
                        &.Email{
                            text-transform: none;
                        }
                        &.error{
                            border-color:  $secondary_light;
                        }
                    }
                    span{
                        @include LoginText;
                        color: $secondary_light;
                    }
                   }
                   .Forgot_Text{
                    @include LoginText;
                    color: $primary_color;
                   }
                    .Checkbox_Wrap{
                        width: 100%;
                        @include Optionflex(flex-start,space-between);
                        a{
                            @include LoginText;
                            color: $secondary_light;
                            margin-left:.5rem;
                        }
                        .Check_Box{
                            @include Optionflex(center,flex-start);
                            input[type="checkbox"]{
                                width: 19.67px;
                                height: 19.67px;
                                border-radius: 2.47647px;
                            }
                            label{
                                @include LoginText;
                                color: $checkbox_border;
                                margin-left: .5rem;
                            }
                        }
                    }
                    .Descrip{
                        @include DescripText;
                        width: 100%;
                        margin-top:3.5rem;
                        color: $checkbox_border;
                        a{
                            color: $secondary_light;
                        }
                     &.Register{
                        margin-top:2rem; 
                     }
                    }

                    button{
                        @include DangerBtn;
                        @include DangerText;
                        color: $white_color;
                        background-color: $secondary_color;
                        outline: none;
                        border: none;
                        margin-top: 1rem;
                    }
                    .Sign_up{
                        text-align: right;
                        margin-top:.5rem;
                        a{
                            @include LoginText;
                            color: $secondary_light;
                            margin-left:.5rem;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:768px){
    .Login_Wrapper{
        flex-direction: column;
        height: auto;
        // gap: 3rem;
        .Left,.Right{
            width: 100%;
        }
        .Left{
            padding-top:3rem;
            .Transport{
                height: 450px;
            }
        }
        .Right{
          overflow: hidden;
          height: 750px;
          margin-top:0rem;
          padding:3rem  0rem;
         
          .LogIn_Form{
            width: 85%;
          }
        }
    }
}

@media (max-width:400px){
    .Login_Wrapper{
        .Left{
            padding-top:3rem;
            .Transport{
                width: 100%;
                height:260px;
            }
        }
        .Right{
          height: 700px;
          &.Register{
            height: 850px;
          }
         
          .LogIn_Form{
            width: 95%;
            .Top{
                flex-direction: column;
            }
          }
        }
    }
}