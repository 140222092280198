@import './mixin.scss';
@import './variables.scss';


.suggestions-field {
    background-color:$white_color;
    box-shadow: 0 8px 16px 0 $dropdown_shadow;
    display: none;
    position: absolute;
    z-index: 10;
    height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
    width:100%!important;
    padding-inline: 1rem;
    cursor: pointer;
  }
  
  .suggestions-field-visible {
    display: block;
  }

.Interstate_Form{
    width: 60%;
    margin-inline: auto;
    margin-bottom: 5rem;
    h3{
        @include TabTitle;
        text-align: center;
        color: $primary_color;
        margin-top:3rem;
    }
    form{
        margin-top: 2rem;
        .Top_Wrap{
            width: 100%;
            @include flex;
            gap: 1rem;
            margin-bottom: 1rem;
            .Form_Control{
                margin-bottom: 0rem;
            }
        }

        .City_Wrap{
            width: 100%;
            @include flex;
            gap: 1rem;
            margin-bottom: 1rem;
            .Form_Control{
                margin-bottom: 0rem;
                &:nth-of-type(2){
                    width:70%;
                }
            }
        }

        .PostCode_Wrap{
            width: 100%;
            @include flex;
            gap: 1rem;
            margin-bottom: 1rem;
            .Form_Control{
                margin-bottom: 0rem;
                position: relative;
                &:nth-of-type(2){
                    width:30%;
                }
            }
            
        }

        .Save_Address{
            width: 100%;
            @include Optionflex(center,flex-start);
            gap: .5rem;
            margin-bottom: 1rem;
            label{
                @include settingModalPlaceholder;
                color: $dark;
            }
        }

        .Form_Control{
            width: 100%;
            margin-bottom: 1rem;
            position: relative;
            label{
                @include settingModalPlaceholder;
                color: $dark;
            }
            input, select{
                width: 100%;
                outline: none;
                height: 38.79px;
                border: 0.874501px solid $input_border;
                background-color: transparent;
                border-radius: 2.6235px;
                padding:.5rem 1rem;
                // text-transform: capitalize;
                &.Country{
                    text-transform: none!important;
                }
                &.error{
                    border-color:  $btn_cancel;
                }
                &::placeholder{
                    @include FormPlaceholder;
                    color: $checkbox_border;
                }
            }
            span{
                @include formTiny;
                color: $btn_cancel;
            }
            
        }
        .FormBtn{
            @include FormBtn;
            color: $white_color;
            background-color:$btn_cancel;
            margin:1.5rem 0rem;
            cursor: pointer;
        }
       
    }
    .Terms{
        color: $checkbox_border;
        @include formTiny;
        a{
            color: $btn_cancel;
        }
    }
    .Nested_Bottom_Wrap{
        margin-top:1.5rem;
        width: 100%;
        @include Optionflex(center,space-between);
    }
}

.Package_Detail{
    width: 60%;
    margin-inline: auto;
    margin-bottom: 5rem;
    h3{
        @include TabTitle;
        text-align: center;
        color: $primary_color;
        margin-top:3rem;
        margin-bottom: 3.5rem;
    }
    .Top{
        .Box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 1rem;
            margin-bottom:.5rem;
            border-bottom: 1px solid $hero_shadow;
            .circle,.Right{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $package_bg_color;
            }
            .Left{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .circle{
                    width: 30px;
                    height: 30px;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                    color: $dark;
                    border-radius: 100%;
                    margin-right: 1rem;
                }
                .Detail{
                    h4{
                       @include WalletEmptyText;
                        color: $dark;
                    }
                    h5{
                        @include PackageSubText;
                        color: $checkbox_border;
                    }
                }
            }
            .Right{
                width: 52.55px;
                height: 25.74px;
                @include flex;
                border-radius: 3px;
                background-color: $package_bg_color;
            }
        }
        .chip{
            width: 91.79px;
            height: 25.74px;
            border-radius: 3px;
            @include settingModalPlaceholder;
            color: $dark;
            background-color: $package_bg_color;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 3rem;
            margin-bottom:2.5rem;
            cursor: pointer;
        }
    }
    h4{
        @include settingModalPlaceholder;
        color: $primary_color;
    }
    ul{
        width: 100%;
        margin-bottom:2.5rem;
        li{
            @include PackageSubText;
            color: $checkbox_border;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 1rem 0rem;
            &:nth-of-type(1){
                border-top: 1px solid $hero_shadow;
            }
            span{
                @include  PackageSpanText;
                color: $dark;
            }
            .btn{
                @include  PackageSpanText;
                width: 52.55px;
                height: 25.74px;
                background-color: $package_bg_color;
                border-radius: 3px;
                color: $primary_color;
                display: flex;
                align-items: center;
                justify-content: center;
                &.change{
                    width:80px;
                }
            }
            border-bottom: 1px solid  $hero_shadow;
        }
    }
    small{
        @include formTiny;
        color: $checkbox_border;
    }
    button{
        @include FormBtn;
        color: $white_color;
        background-color:$btn_cancel;
        margin:1.5rem 0rem;
        border: none;
        cursor: pointer;
    }
}

.Package_Modal_Wrapper{
    width: 450px;
    height: 100%;

    .Triple_Box {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1rem;
    }
    .Box{
        width: 100%;
        height: auto;
        margin-bottom: .6rem;
        label{
            @include PackageSubText;
            color: $dark;
        }
        input, textarea, select{
            width: 100%;
            height: 45.16px;
            box-sizing: border-box;
            border-radius: 2.6235px;
            background-color: $white_color;
            outline: none;
            border:1px solid $input_border;
            padding:.5rem 1rem;
            @include PackageSubText;
            color: $dark;
            font-weight: normal;
            margin-top:.3rem;
            &::placeholder{
                @include PackageSubText;
                color: $checkbox_border;
            }
            &.error{
                border-color:$btn_cancel!important;
            }
        }
        textarea{
            min-height: 100px;
        }
        span{
            @include formTiny;
            color: $btn_cancel;
        }
    }
    .Top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: .5rem;
    }
    p{
        @include PackageSubText;
        color: $checkbox_border;
    }
    span.bottom_text{
        // @include placeholderText;
        // color: $grey_color;
    }
    button{
        @include FormBtn;
        color: $white_color;
        background-color:$btn_cancel;
        margin:1.5rem 0rem;
        border: none;
        cursor: pointer;
    }
}

.Payment_Method{
    width: 60%;
    margin-inline: auto;
    margin-bottom: 5rem;
    h3{
        @include TabTitle;
        text-align: center;
        color: $primary_color;
        margin-top:3rem;
        margin-bottom: 3.5rem;
    }
    .Box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:1rem 0rem;
        box-sizing: border-box;
        border-bottom: 1px solid $hero_shadow;
        h6{
            @include PackageSpanText;
            color: $checkbox_border;
        }
        input[type="radio"]{
            appearance: none;
            -webkit-appearance: none;
            width: 24px;
            height: 24px;
            background-color:$white_color;
            border: 6px solid #8b8b8b;
            border-radius: 100%;
            &:checked{
                border-color: $btn_cancel;
                // background-color: $primary_color;
            }
        }
        .Detail{
            h4{
                @include paymentTitle;
                color: $dark;
            }
            h5{
                @include PackageSpanText;
                color: $checkbox_border;
            }
        }
    }
    .Balance{
        margin:1.5rem  0rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .Wrap{
            display: flex;
            align-items:center;
            // justify-content: flex-start;
            h2{
                @include TabTitle;
                color: $primary_color;
              }
              h5{
                @include PackageSpanText;
                color: $primary_color;
              }
        }
        h6{
            @include CircleTabText;
            color: $btn_cancel;
            &.Danger_Balance{
                // color: $fade_error;
            }
        }
    }
    small{
        @include formTiny;
        color: $checkbox_border;
    }
    button{
        @include FormBtn;
        color: $white_color;
        background-color:$btn_cancel;
        margin:1.5rem 0rem;
        border: none;
        cursor: pointer;
    }
}

.Confirm{
    width: 60%;
    margin-inline: auto;
    margin-bottom: 5rem;
    h3{
        @include TabTitle;
        text-align: center;
        color: $primary_color;
        margin-top:3rem;
        margin-bottom: 3.5rem;
    }
    .Box{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $hero_shadow;
        box-sizing: border-box;
        padding: 1rem 0rem;
        p{
            @include ShipmentTabText;
            color: $checkbox_border;
            white-space: pre-line!important;
            text-transform: uppercase;
        }
        .Right{
            display: flex;
            align-items:flex-end;
            justify-content:flex-end;
            flex-direction: column;
            select{
                width: 200px;
                outline: none;
                height: 38.79px;
                border: 0.874501px solid $input_border;
                border-radius: 2.6235px;
                padding:.5rem;
                @include ShipmentsTableBodyTitle;
                color: $dark;
            }

            h4{
                @include ShipmentsTableBodyTitle;
                color: $dark;
            }
            h6{
                @include ShipmentsTableBodyTitle;
                color: $checkbox_border;
            }
        }
    }
    .Bottom_Box{
        display: flex;
        align-items:flex-end;
        justify-content: flex-start;
        flex-direction: column;
        h3{
            @include ShipmentsTableBodyTitle;
            color:$primary_color;
            margin: 0rem;
            margin-top: 1rem;
        }
        h5{
            @include ShipmentTabText;
            color:$checkbox_border;
        }
    }
    button{
        @include FormBtn;
        color: $white_color;
        background-color:$btn_cancel;
        margin:1.5rem 0rem;
        border: none;
        cursor: pointer;
    }
    small{
        @include formTiny;
        color: $checkbox_border;
    }
}


.Successfull{
    width: 60%;
    margin-inline: auto;
    margin:10rem  auto;
    @include flex;
    flex-direction: column;
    h4{
        @include BookShipmentTitle;
        color: $dark;
        margin:1rem 0rem;
        text-align: center;
    }
    p{
        @include BookShipmentText;
        color: $checkbox_border;
    }
    .Btn{
        @include BookShipmentBtnText;
        @include flex;
        width: 131px;
        height: 38.24px;
        margin-top: 2rem;
        cursor: pointer;
        color: $white_color;
        background-color: $primary_color;
        border-radius: 3px;
    }
}



@media (max-width:768px){
    .Interstate_Form,
    .Package_Detail,
    .Payment_Method,
    .Confirm,
    .Successfull{
        width:80%;
    }
    .Interstate_Form{
        form{
            .PostCode_Wrap{
                .Form_Control{
                }           
            }
        }
    }
}

@media (max-width:400px){
    .Interstate_Form{
        width:95%;
        h3{
            @include PageTitleMobile;
        }
        form{
            .Top_Wrap{
               flex-direction: column;
            }

            .City_Wrap{
               flex-direction: column;
                .Form_Control{
                    margin-bottom: 0rem;
                    &:nth-of-type(2){
                        width:100%;
                    }
                }
            }

            .PostCode_Wrap{
                flex-direction: column;
                .Form_Control{
                    margin-bottom: 0rem;
                    &:nth-of-type(2){
                        width:100%;
                    }
                }
            }
        }
        .Nested_Bottom_Wrap{
            flex-direction: column;
            gap: .5rem;
            .Nested_{}
        }
    }
    .Package_Detail,
    .Payment_Method,
    .Confirm,
    .Successfull{
        width:95%;
    }
    .Successfull{
        margin-top: 2rem;
        p{
            text-align: center;
        }
    }
    .Package_Modal_Wrapper{
        width:250px;
        .Triple_Box {
            display: block;
        }
        .Box{
           margin-bottom: .3rem;
            textarea{
                min-height:50px;
            }
        }
        .Top{
            flex-direction: column;
           align-items: flex-start;
           gap:.3rem;
        }
        p{
            display: none;
        }
        button{
            margin-bottom:0rem;
        }
    }
    .css-w3j0kx{
        padding:1rem!important;
    }
    .Confirm{
       
        .Box{
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
          
            .Right{
                align-items: flex-start;
                width: 100%;
                select{
                    width: 100%;
                }
            }
        }
      
    }
}