@import './mixin.scss';
@import './variables.scss';

.PrivacyPolicy {
    margin: 5rem 0;
    padding: 0 8rem !important;

    .Policy-Header {
        text-align: center;
    }

    .policy-list {
        list-style: decimal;
    }

    .text-list-header {
        font-weight: 600;
        font-size: 20px;

        &-sub {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .unordered {
        list-style: disc;
    }
}

@media (max-width:768px){
    .PrivacyPolicy {
        margin: 5rem 0;
        padding: 0 !important;
    }
}