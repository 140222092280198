@import './mixin.scss';
@import './variables.scss';

.Price_Page{
    width: 70%;
    margin-inline: auto;
    margin:5rem auto 2rem auto;
    h3{
        @include TabTitle;
        text-align: center;
        color: $primary_color;
        margin-top:3rem;
    }
    
    .Price_Card{
        width: 100%;
        background-color:$white_color;
        box-shadow: 17.6775px 17.6775px 35.355px -10.6065px $hero_shadow;
        border-radius: 9.05154px;
        height: auto;
        padding:1.2rem;
        border: 1px solid $input_border;
        margin-bottom:.5rem;
        &.active{
            background-color:$sidebar_color;
        }
        .Logo {
            // background-image: url('../img/Ella.png');
           img {
            width: 70px;
            height: 70px;
           }
        }
        // &:nth-of-type(1){
        //     .Logo{
        //         background-image: url('../img/shDHL.jpeg');
        //     }
        //     .Right{
        //         h2{
        //             @include TabTitle;
        //             color: $secondary_color;
        //         }
        //     }
        // }
        // &:nth-of-type(2){
        //     .Logo{
        //         background-image: url('../img/UP.jpg');
        //     }
        // }
        // &:nth-of-type(3){
        //     .Logo{
        //         background-image: url('../img/FedX.png');
        //     }
        // }
        // &:nth-of-type(4){
        //     .Logo{
        //         background-image: url('../img/FedX.png');
        //     }
        // }
        .Wrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .Logo{
                width: 70px;
                height: 70px;
                @include LogoBg;
                
              }
            .Left{
                font-weight: 600;
                margin: 0rem;
                font-size: 14px;
                &.Name{
                    font-size:20px;
                }
            }
            .Right{
                font-size: 13px;
                color: $gray_color;
            }
        }
    }
    button{
        @include FormBtn;
        color: $white_color;
        background-color:$btn_cancel;
        margin:1.5rem 0rem;
        border: none;
        cursor: pointer;
    }
    // .Price_Wrap{
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     .Price{
    //         width: 30%;
    //         height: 38.79px;
    //        background-color:$tab_bg;
    //         border-radius: 2.6235px;
    //         padding:.5rem 1rem;
    //         font-size: 25px;
    //         font-weight:600;
    //         @include Optionflex(center,flex-start);
    //         cursor:help;
    //         color: $secondary_color;
    //     }
    // }

    .Nested_Bottom_Wrap{
        margin-top: 3rem;
        @include Optionflex(center,space-between);
    }
    
}

@media (max-width:768px){
    .Price_Page{
        width: 80%;
    }
}

@media (max-width:400px){
    .Price_Page{
        width:100%;
    }
}