@import './mixin.scss';
@import './variables.scss';

.btnTab {
    background-color: transparent !important;
    color: $primary_color !important;
    border: 1px solid $primary_color !important;
}

// button:disabled {
//     background-color: $gray_color;
// }