@import './mixin.scss';
@import './variables.scss';

.Interstate_Shipment{
    padding-inline: 1rem;
    
}



@media (max-width:768px){
    .Interstate_Shipment{
    }
}

@media (max-width:400px){
    .Interstate_Shipment{
      h3{
        
      }
    }
}