@import './mixin.scss';
@import './variables.scss';

.Tab_Wrapper{
    @include Optionflex(center,space-between);
    align-items: center;
    width: 100%;
    margin-inline: auto;
    margin-top:2rem;
    cursor: pointer;
    button{
        @include Optionflex(center,flex-start);
        gap: .3rem;
        border: none;
        outline: none;
        background-color: transparent;
        &.active{
            .circle{
                background-color:$primary_color;
                color: $white_color;
            }
            p{
                color: $primary_color;
            }
        }
        .circle{
            width: 25px;
            height: 25px;
            border-radius: 100%;
            @include flex;
            @include  CircleTabText;
            background-color: $arrow_btn_color;
            color: $checkbox_border;
        }
        p{
            @include ShipmentsTableBodyTitle;
            color: $checkbox_border;
            margin: 0;
        }
    }
}
.Breadcrum{
    width: 100%;
    padding-inline:1rem;
    text-transform: capitalize;
    margin-bottom: 1rem;
    margin-top:6rem;
    @include Optionflex(center,space-between);
    h5{
        @include PageTitle;
        color: $primary_color;
    }
    .Cost_Wrap{
        @include Optionflex(center,flex-end);
        h3{
           &.Price{
            color: $secondary_color;
           }
        }
    }
}

.Check_Circle {
    width: 64px;
    height: 64px;
    background-color: rgb(19, 167, 19);
    color: #ffffff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 64px;
    font-weight: 700;
}

.BookedTitle {
    font-weight: 600 !important;
    font-size: 24px !important;
    margin-bottom: 1rem;
    text-align: center;
}

.BookedText {
    text-align: center;
    font-size: 16px !important;
}

.ImgContain {
    border: 1px dashed $checkbox_border;
    border-radius: 10px;

    .ImgInput {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100%;
        opacity: 0;
    }

    .UploadIcon {
        color: $gray_color;
        text-align: center;
        margin-bottom: .5rem;
        font-size: 1.5rem;
    }

    p {
        color: $gray_color;
        text-align: center;
    }
}

@media (max-width:400px){
    .BookedTitle {
        font-size: 18px !important;
    }
    .BookedText {
        font-size: 14px !important;
    }
    .Tab_Wrapper{
        // @include Optionflex(flex-start,space-between);
        // flex-wrap: wrap;
        display: none;
        .Box{
            width: 50%;
            flex-wrap: wrap;
            gap: .3rem;
            margin-bottom:1.5rem;
        }
    }
    .Breadcrum{
        h5{
            @include TabTitle;
        }
    }
}

.helperText {
    font-size: 12px;
}