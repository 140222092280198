@import './mixin.scss';
@import './variables.scss';

.Breadcrum{
    width: 100%;
    padding-inline:1rem;
    text-transform: capitalize;
    margin-bottom: 1rem;
    margin-top:6rem;
    @include Optionflex(center,space-between);
    h5{
        @include PageTitle;
        color: $primary_color;
    }
    .Cost_Wrap{
        @include Optionflex(center,flex-end);
        h3{
           &.Price{
            color: $secondary_color;
           }
        }
    }
}

.pageContain {
    width: 70%;

    h3{
        @include WarehouseTitle;
        text-align: center;
        color: $little_deep_primary;
        margin-bottom: 2rem;
    }
}

@include respond(tablet) {
    .pageContain {
        width: 100%;
    }

    h3{
        margin-bottom: 1rem;
    }
}

.PageWrapper {
    padding-inline: 1rem;
    width: 100%;

    .rateCentered {
        justify-content: center;
        width: 100%;
        display: flex;
    }

    .toggleRateType {
        width: 32rem;
        height: 2.8rem;
        border: 1px solid $primary_color;
        display: flex;
    
        button {
            height: 100%;
            width: 50%;
        
            border: none;
            color: $primary_color;
            font-size: 1rem;
    
            @include respond(tablet) {
                font-size: 1rem;  
            }
        }
    
        .rateActive {
            background-color: $primary_color !important;
            color: $white_color !important;
        }
    
        @include respond(tablet) {
            width: 100%;  
        }
    }

    .Rate_form{
        width: 60%;
        margin-inline: auto;
        margin-bottom: 5rem;
        
        h3{
            @include TabTitle;
            text-align: center;
            color: $primary_color;
            margin-top:3rem;
        }
        form{
            margin-top: 2rem;
            .Top_Wrap{
                width: 100%;
                @include flex;
                gap: 1rem;
                margin-bottom: 1rem;
                .Form_Control{
                    margin-bottom: 0rem;
                }
            }
    
            .City_Wrap{
                width: 100%;
                @include flex;
                gap: 1rem;
                margin-bottom: 1rem;
                .Form_Control{
                    margin-bottom: 0rem;
                    &:nth-of-type(2){
                        width:70%;
                    }
                }
            }
    
            .PostCode_Wrap{
                width: 100%;
                @include flex;
                gap: 1rem;
                margin-bottom: 1rem;
                .Form_Control{
                    margin-bottom: 0rem;
                    position: relative;
                    &:nth-of-type(2){
                        width:30%;
                    }
                }
                
            }
    
            .Save_Address{
                width: 100%;
                @include Optionflex(center,flex-start);
                gap: .5rem;
                margin-bottom: 1rem;
                label{
                    @include settingModalPlaceholder;
                    color: $dark;
                }
            }
    
            .Form_Control{
                width: 100%;
                margin-bottom: 1rem;
                position: relative;
                label{
                    @include settingModalPlaceholder;
                    color: $dark;
                }
                input{
                    width: 100%;
                    outline: none;
                    height: 38.79px;
                    border: 0.874501px solid $input_border;
                    border-radius: 2.6235px;
                    padding:.5rem 1rem;
                    text-transform: capitalize;
                    &.Country{
                        text-transform: none!important;
                    }
                    &.error{
                        border-color:  $btn_cancel;
                    }
                    &::placeholder{
                        @include FormPlaceholder;
                        color: $checkbox_border;
                    }
                }
                span{
                    @include formTiny;
                    color: $btn_cancel;
                }
                
            }
            .FormBtn{
                @include FormBtn;
                color: $white_color;
                background-color:$btn_cancel;
                margin:1.5rem 0rem;
                cursor: pointer;
            }
           
        }

        @include respond(tablet) {
            width:80%;
        }

        @include respond(phone) {
            width:100%;
            h3{
                @include PageTitleMobile;
            }
            form{
                .Top_Wrap{
                flex-direction: column;
                }

                .City_Wrap{
                flex-direction: column;
                    .Form_Control{
                        margin-bottom: 0rem;
                        &:nth-of-type(2){
                            width:100%;
                        }
                    }
                }

                .PostCode_Wrap{
                    flex-direction: column;
                    .Form_Control{
                        margin-bottom: 0rem;
                        &:nth-of-type(2){
                            width:100%;
                        }
                    }
                }
            }
            .Nested_Bottom_Wrap{
                flex-direction: column;
                gap: .5rem;
                .Nested_{}
            }
        }
    }
}

.centralRateType {
    display: flex;
    justify-content: center;
    width: 100%;
}

.Rate_Modal {

    h4 {
        font-size: 1.2rem;
    }

    padding-bottom: 0 !important;

    .Top_section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.2rem;
    }

    .Carrier_item {
        padding-inline: 1rem;
        @include flex;
        justify-content: space-between;
        gap: 4rem;
        margin-bottom: 1.2rem;
        
        .Carrier_details {
            display: flex;
            gap: 1rem;
    
            .Carrier_name {
                font-size: 1rem;
                font-weight: 500;
                margin-bottom: 0;
            }
    
            .Carrier_delivery {
                color: $grey;
                font-size: 14px;
            }
    
            .Carrier_amount {
                font-size: 1.2rem;
                font-weight: 500;
            }
        }
    
        @include respond(tablet) {
            img {
                width: 50px;
                height: 50px;
            }
    
            .Carrier_amount {
                font-size: .8rem;
                white-space: nowrap;
            }
        }
    
        @include respond(tabletUp) {
            img {
                width: 50px;
                height: 50px;
            }
        }
    
    }
}

