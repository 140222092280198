@import './mixin.scss';
@import './variables.scss';

.Shipment_Logistic{
    width: 95%;
    margin-inline: auto;
    height: auto;
    h2{
        @include TabTitle;
        color: $dark;
        margin-bottom: 2rem;
        margin-top: 2.5rem;
    }
    .Layout{
        @include Optionflex(flex-start,flex-start);
        gap: 2rem;
        .Left{
            width: 45%;
            .Custom_Card{
                width: 100%;
                height:145px;
                border-radius: 3px;
                background-color:$tab_bg;
                @include Optionflex(center,space-between);
                cursor: pointer;
                border: 1px solid $input_border;
                margin-bottom:1.5rem;
                &.active{
                    background: $sidebar_color;
                    .Inner_Card{
                        .Img_Box{
                            background-color: $secondary_color;
                            svg{
                                fill: $white_color;
                            }
                        }
                        .Detail_Box{
                            h3{
                                color: $primary_color;
                                // svg{
                                //     fill:$white_color;
                                // }
                            }
                            p{
                                color: $checkbox_border;
                            }
                        }
                    } 
                }
                &:hover{
                    background: $sidebar_color;
                    .Inner_Card{
                        .Img_Box{
                            background-color: $secondary_color;
                            svg{
                                fill: $white_color;
                            }
                        }
                        .Detail_Box{
                            h3{
                                color: $primary_color;
                                // svg{
                                //     fill:$white_color;
                                // }
                            }
                            p{
                                color: $checkbox_border;
                            }
                        }
                    } 
                }
                .Inner_Card{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 1rem;
                    padding-left: 2rem;
                    .Img_Box{
                        width: 58.09px;
                        height: 58.38px;
                        background-color: $svg_bg;
                        border-radius: 2.61577px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg{
                            fill: $light_gray;
                        }
                    }
                    .Detail_Box{
                        h3{
                            @include ShipmentTabTitle;
                            color: $dark;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            // svg{
                            //     fill: $black_color;
                            // }
                        }
                        p{
                            @include ShipmentTabText;
                            color:$checkbox_border;
                        }
                    }
                }
            }
        }
        .Right{
            width:25%;
            @include flex;
            flex-direction: column;
            gap: .5rem;
            .Shipment_Top_Card{
                width: 100%;
                @include Optionflex(flex-start,center);
                flex-direction: column;
                gap: .5rem;
            }
            .Bank{
                width: 100%;
                height: auto;
                background-color: $white_color;
                border: 0.878843px solid $grap_border;
                box-shadow: 3.51537px 3.51537px 17.5769px -8.78843px $hero_shadow;
                border-radius: 10.5461px;
                padding:1rem;
                @include Optionflex(flex-start,center);
                flex-direction: column;
                h3{
                  @include BankTitle;
                  color: $dark;  
                }
                .Bank_Card{
                    width: 100%;
                    height:auto;
                    border-radius: 10.5461px;
                    background-color: $primary_color;
                    margin-top:.7rem;
                    @include flex;
                    flex-direction: column;
                    gap: 1.5rem;
                    padding:1.5rem  1rem;
                    .Bank_Top{
                        width: 100%;
                        @include Optionflex(center,space-between);
                        .Left{
                            h4{
                             @include BankCardText;
                             color: $white_color;  
                            }
                            h3{
                                @include BankCardTitle;
                                color: $white_color;  
                            }
                        }
                        .Ella{
                            width: 32px;
                            height: 28px;
                            border-radius: 1.75769px;
                            background-color: $white_color;
                            @include flex;
                            .Image{
                            width: 22.87px;
                            height: 14.61px;
                            @include Bg;
                            background-image: url("../img/Ella.png");
                            }
                        }
                    }
                    .Bank_Bottom{
                        width: 100%;
                        @include Optionflex(center,space-between);
                        .Left{
                            h4{
                             @include BankCardText;
                             color: $white_color;  
                            }
                            h3{
                                @include BankCardTitle;
                                color: $white_color;  
                            }
                        }
                        .Bottom_Right{
                            @include Optionflex(flex-end,center);
                            flex-direction: column;
                           
                            h4{
                                @include BankCardText;
                                color: $white_color;  
                               }
                            h5{
                            @include paymentTitle;
                            color: $white_color;  
                            }
                               
                        }
                    }
                }  
            }
        }
    }
    
}

@media (max-width:768px){
    .Shipment_Logistic{
        .Layout{
            .Left{
                width: 60%;
            }
            .Right{
                width:40%;
            }
        }
        
    }
}

@media (max-width:400px){
    .Shipment_Logistic{
        .Layout{
            flex-direction:column-reverse;
            .Left{
                width: 100%;
            }
            .Right{
                width:100%;
                .Shipment_Top_Card{
                    gap: 1.5rem;
                }
                .Bank{
                    margin-top: 1.5rem;
                    .Bank_Card{
                        .Bottom_Right{
                            width: 100%;
                            @include Optionflex(center,space-between);  
                        }
                    }
                }
            }
        }
        
    }
}