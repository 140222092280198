@import './mixin.scss';
@import './variables.scss';

.Card{
    width: 100%;
    background-color:$white_color;
    box-shadow: 17.6775px 17.6775px 35.355px -10.6065px $hero_shadow;
    border-radius: 9.05154px;
    height: auto;
    padding:1.2rem;
    border: 1px solid $input_border;
    &:nth-of-type(1){
        background-color: $primary_color;
        box-shadow: 17.6775px 17.6775px 35.355px -10.6065px $dash_first_card_shadow;
        h6,h3{
            color: $white_color;
        }
        .Wrap{
            h5{
                color:$white_color;
                span{
                    color: $white_color;
                }
            }
            svg{
                color:$white_color;
            }
        }
    }
    h6{
        @include DashCardTopBalanceText;
        color: $placeholder_color;
        opacity: .9;
    }
    h3{
        @include DashCardTopTitleText;
        color: $placeholder_color;
        margin:1.2rem 0rem;
    }
    .Wrap{
        @include Optionflex(center,space-between);
        h5{
            @include DashCardTopBottomText;
            color:$placeholder_color;
            span{
                opacity: .4;
                color: $placeholder_color;
            }
        }
        svg{
            color: $gray_color;
            height: 12px;
        }
    }
}