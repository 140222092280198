@import './mixin.scss';
@import './variables.scss';


.css-uwt1wi-MuiDrawer-docked{
    .MuiDrawer-paper{
        background-color:$sidebar_color;
        &::-webkit-scrollbar {
            width: 3px!important;     
        }
        &::-webkit-scrollbar-track {
            background-color: $home_bg!important;
            -webkit-border-radius: 1px!important;
        }
        &::-webkit-scrollbar-thumb:vertical {
            background-color: $input_border!important;
            -webkit-border-radius: 0px!important;
            -webkit-width:10!important;
        }
        &::-webkit-scrollbar-thumb:vertical:hover {
            background:$input_border!important;
        }
    }
}

.css-rkcbok-MuiModal-root-MuiDrawer-root{
    .MuiDrawer-paper{
        background-color:$sidebar_color;
        &::-webkit-scrollbar {
            width: 3px!important;     
        }
        &::-webkit-scrollbar-track {
            background-color: $home_bg!important;
            -webkit-border-radius: 1px!important;
        }
        &::-webkit-scrollbar-thumb:vertical {
            background-color: $input_border!important;
            -webkit-border-radius: 0px!important;
            -webkit-width:10!important;
        }
        &::-webkit-scrollbar-thumb:vertical:hover {
            background:$input_border!important;
        }
    }
}




.Main{
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    box-sizing: border-box;
    background-color:$white_color;
    padding-top:0rem;
    position: relative;
}

.SideBar_Logo{
width: 72px;
height: 46px;
@include Bg;
background-image: url("../img/Ella.png");
margin-top:1.5rem;
margin-left: 2rem;
margin-bottom:6rem;
}

.Desktop_Anchor_Wrapper{
    // border:1px solid red;
    width: 80%;
    margin-inline:auto;
    padding: 7.61905px 22.8571px 7.61905px 15.2381px;
    margin-bottom: 1rem;
    &.active{
        background-color: $primary_color;
        border-radius: 3.80952px;
        &:hover{
            svg{
                fill: $white_color;
            }
            a{
                color: $white_color;
            }
        }
        svg{
            fill: $white_color;
        }
        a{
            color: $white_color;
        }
    }
    &:hover{
        svg{
            fill: $primary_color;
        }
        a{
            color: $primary_color;
        }
    }
    svg{
        fill:$svg_color;
        width: 20px;
        height: 20px;
        margin-right:11.43px;
    }
    a{
        @include AnchorText;
        color: $anchor_link;
    }
}

.Dropdown_Anchor_Link{
    width: 80%;
    height: auto;
    margin-inline:auto;
    .Desktop_Anchor_Wrapper{
        width:100%;
        margin-inline:auto;
        padding: 7.61905px 12px;
        padding-right: 0rem;
        position: relative;
        &.active{
            background-color: $primary_color;
            border-radius: 3.80952px;
            &:hover{
                svg{
                    fill: $white_color;
                }
                a{
                    color: $white_color;
                }  
            }
            svg{
                fill: $white_color;
            }
            a{
                color: $white_color;
            }
        }
        &:hover{
            svg{
                fill: $primary_color;
            }
            a{
                color: $primary_color;
            }
        }
        .Chevron{
           position: absolute;
           right: 0%;
           top: 50%;
           transform: translateY(-50%);
           transition:all .3s ease-in-out;
        }
        .ChevronDown{
            position: absolute;
            right: 0%;
            top: 50%;
            transform: translateY(-50%);
            transition:all .3s ease-in-out;
            fill: $secondary_color!important;
        }
        svg{
            fill:$svg_color;
            width: 20px;
            height: 20px;
            margin-right:11.43px;
            margin-bottom: .2rem;
        }
        a{
            @include AnchorText;
            color: $anchor_link;
        }      
    }
    .DropDown_Wrap{
        width: 100%;
        max-height:0px;
        margin-inline:auto;
        padding-left:45px;
        overflow: hidden;
        transition:height .4s ease-in-out;
        a{
            display: none;
            @include AnchorText;
            color: $anchor_link;
            margin-bottom: .5rem;
            &.active{
                color: $primary_color;
            }
        }
        &.active{
            max-height:120px;
            a{
                display: block;
            }
        }
    } 
    
}

.Log_Out_Btn{
    width: 80%;
    margin-inline:auto;
    padding: 7.61905px 22.8571px 7.61905px 15.2381px;
    margin-top: 5rem;
    display: flex;
    cursor: pointer;
    &:hover{
        p{
            color: $primary_color;
        }
        svg{
            stroke: $primary_color;
        }
    }
    svg{
        stroke:$svg_color;
        width: 20px;
        height: 20px;
        margin-right:11.43px;
    }
    p{
        @include AnchorText;
        color: $anchor_link;
    }
}