@import './mixin.scss';
@import './variables.scss';

.Dashboard_Layout{
    width: 100%;
    margin-inline:auto;
    height: auto;
    padding-inline:1rem; 
    @include Optionflex(flex-start,space-between);
    gap: 1.5rem;
    .Left{
        width:65%;
        .Top_Card{
            @include flex;
            gap:1rem;
        }
        .Dash_Analysis{
            width: 100%;
            margin:3.5rem 0rem;
            h3{
                @include DashAnalysisTitle;
                color: $dark;
            }
            .Graph_Card{
                margin-top: 1rem;
                height:400px;
                background-color: $white_color;
                border: 0.883876px solid $grap_border;
                box-shadow: 3.53551px 3.53551px 17.6775px -8.83876px $hero_shadow;
                border-radius: 10.6065px;
                overflow: hidden;
                .Graph_Top{
                    width: 90%;
                    margin-inline: auto;
                    @include Optionflex(center,flex-end);
                    gap: 1rem;
                    padding:1rem 0rem;
                    select{
                        width: 126px;
                        height: 28px;
                        border: 0.441938px solid $grap_border;
                        border-radius: 5.30326px;
                        color: $placeholder_color;
                        @include  DashCardTopBottomText;
                        outline: none;
                    }
                }
            }
        }
        .Dash_Recent_Shipment{
            width: 100%;
            margin: 2rem 0rem;
            h3{
                @include DashAnalysisTitle;
                color: $dark;
            }
            .Shipment_Table_Wrapper{
                width: 100%;
                min-height: 350px;
                overflow: hidden;
                border: 0.878843px solid $grap_border;
                background-color: $white_color;
                box-shadow: 3.51537px 3.51537px 17.5769px -8.78843px $hero_shadow;
                border-radius: 9px;
                transition: height .4s ease-in-out;
                &.active{
                    height: auto;
                    padding-bottom:1rem;
                }
                .Recent_Top{
                    width:95%;
                    height: auto;
                    margin-inline: auto;
                    padding:1rem 0rem;
                    margin-bottom:.5rem;
                    @include Optionflex(center,space-between);
                    border-bottom: 1px solid $dash_stroke;
                    position: relative;
                    &::before{
                        width: 120px;
                        content: '';
                        height: 2px;
                        bottom: -1px;
                        left: 0px;
                        position: absolute;
                        background-color: $primary_color;
                    }
                    h4{
                        @include RecentText;
                        color: $primary_color;
                    }
                    button{
                        @include ViewMore;
                        color: $placeholder_color;
                        background-color: transparent;
                        border: none;
                        outline: none;
                        svg{
                            margin-left: .4rem;
                        }
                    }
                }
                table{
                    width:95%;
                    height:auto;
                    margin-inline: auto;
                    thead{
                        width: 100%;
                        th{
                            width: calc(100%/3.5);
                            padding:.5rem 0rem;
                            @include  DashTableText;
                            font-weight: 600;
                            color: $dash_table_header_color;
                        }
                    }
                   tbody{
                    width: 100%;
                    tr{
                        width: 100%;
                        &:nth-of-type(even){
                            background-color:$home_bg;
                        }
                        td{
                            @include  DashTableText;
                            color: $dash_table_body_color;
                            padding: 1rem 0rem;
                            .Sender_Wrap{
                                width: 100%;
                                @include Optionflex(center,flex-start);
                                .Avatar{
                                    width: 30.48px;
                                    height: 30.48px;
                                    border-radius: 100%;
                                    @include Bg;
                                    background-image: url("../img/send.png")
                                }
                            }
                            .Btn{
                                @include TableBtn;
                                width: auto!important;
                                white-space:pre;
                                text-transform: uppercase;
                                cursor: pointer;
                                &.success{
                                    background-color:$btn_bg_success;
                                    color: $btn_success;
                                }
                                &.cancel{
                                    background-color:$btn_bg_cancel;
                                    color:$btn_cancel;
                                }
                                &.active{
                                    background-color:$btn_bg_active;
                                    color:$btn_active;
                                }
                                &.pending{
                                    background-color:$btn_bg_pending;
                                    color:$btn_pending;
                                }
                            }
                        }
                    }
                   }
                }
                .No_Data_div{
                    width: 100%;
                    height: 100%;
                    @include flex;
                    h5{
                        @include RecentText;
                        color: $primary_color;
                    }
                }
            }
        }
    }
    .Right{
        width:35%;
        .Shipment_Track{
            width: 100%;
            height: auto;
            background-color: $white_color;
            border: 0.878843px solid $grap_border;
            box-shadow: 3.51537px 3.51537px 17.5769px -8.78843px $hero_shadow;
            border-radius: 10.5461px;
            padding:1.5rem;
            h3{
                @include DashAnalysisTitle;
                color: $dark;
                margin-bottom: 1rem
            }
            .Tracker_Wrap{
                @include flex;
                flex-direction: column;
                gap: 1rem;
                .Progress_Wrap{
                    width: 100%;
                    @include Optionflex(center,flex-start);
                    border: 0.439422px solid $grap_border;
                    background-color: $white_color;
                    box-shadow: 3.51537px 3.51537px 17.5769px -8.78843px $hero_shadow;
                    border-radius: 5.27306px;
                    padding:1rem  .5rem;
                    gap: 1rem;
                    position: relative;
                    .Arrow_right{
                        position: absolute;
                        top: 50%;
                        right: 5%;
                        transform: translateY(-50%);
                    }
                    .Circle{
                        // border: 1px solid red;
                        width:80px;
                        height:80px;
                        overflow: hidden;
                        position: relative;
                        overflow: hidden;                  
                    }
                    .Middle{
                        @include flex;
                        gap:.5rem;
                        flex-direction: column;
                        h4{
                            @include ProgressTitle;
                            color: $dark;
                        }
                        p{
                            @include ProgressText;
                            color: $placeholder_color;
                        }
                    }
                }
            }
            
        }
        .Logistic_Bank{
            width: 100%;
            height: 302.32px;
            margin-top: 0rem;
            background-color: $white_color;
            border: 0.878843px solid $grap_border;
            box-shadow: 3.51537px 3.51537px 17.5769px -8.78843px $hero_shadow;
            border-radius: 10.5461px;
            padding: 2rem;
            h3{
              @include BankTitle;
              color: $dark;  
            }
            .Bank_Card{
                width: 100%;
                height: 125px;
                border-radius: 10.5461px;
                background-color: $primary_color;
                margin:2rem 0rem;
                @include flex;
                flex-direction: column;
                gap: 1.5rem;
                padding-inline:2rem;
                .Bank_Top{
                    width: 100%;
                    @include Optionflex(center,space-between);
                    .Left{
                        h4{
                         @include BankCardText;
                         color: $white_color;  
                        }
                        h3{
                            @include BankCardTitle;
                            color: $white_color;  
                        }
                    }
                    .Ella{
                        width: 32px;
                        height: 28px;
                        border-radius: 1.75769px;
                        background-color: $white_color;
                        @include flex;
                        .Image{
                        width: 22.87px;
                        height: 14.61px;
                        @include Bg;
                        background-image: url("../img/Ella.png");
                        }
                    }
                }
                .Bank_Bottom{
                    width: 100%;
                    @include Optionflex(center,space-between);
                    .Left{
                        h4{
                         @include BankCardText;
                         color: $white_color;  
                        }
                        h3{
                            @include BankCardTitle;
                            color: $white_color;  
                        }
                    }
                    .Bank_Name{
                        text-align: right; 
                        h4{
                            @include BankCardText;
                            color: $white_color;  
                           }
                           h3{
                               @include BankCardTitle;
                               color: $white_color;  
                           }
                           h5{
                            @include paymentTitle;
                            color: $white_color;  
                           }
                    }
                }
            }
            .Add_Btn{
                width: 146.82px;
                height: 31.58px;
                background-color: $white_color;
                border: 0.439422px solid $add_Btn_Color;
                border-radius: 5.27306px;
                @include DashCardTopBottomText;
                @include flex;
                cursor: pointer;
                margin-inline: auto;
            }
        }
    }
}


@media(max-width:768px){
    .Dashboard_Layout{
        flex-direction: column;
        .Left,.Right{
            width: 100%;
        }
    }
}

@media(max-width:400px){
    .Dashboard_Layout{
        padding-inline: .5rem;
       .Left{
        .Top_Card{
          flex-direction: column;
        }
        .Dash_Recent_Shipment{
            .Shipment_Table_Wrapper{
                // height: auto;
                table{
                    thead{
                       display: none;
                    }
                   tbody{
                    width: 100%;
                    tr{
                        td{
                            width: 100%;
                            padding: 1rem;
                            display: block;
                        }
                    }
                   }
                }
            }
        }
       }
    }
}