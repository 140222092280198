@import './mixin.scss';
@import './variables.scss';


.Error_Page{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .Error_Image{
      width: 100%;
      height: 100vh;
      @include Bg;
      background-image: url("../Gif/404-error-2.gif");
      position: relative;
      .Home_Btn{
        position: absolute;
        bottom: 20%;
        left: 50%;
        transform: translateX(-50%);
        color: $white_color;
        cursor: pointer;
      }
    }
}