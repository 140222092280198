/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('./Assets/fonts/fellix/stylesheet.css');
@import url('./Assets/fonts/coolvetica/stylesheet.css');

body {
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'DM Sans', sans-serif; */
}
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Fellix', sans-serif;
}

code {
  font-family: 'Roboto', sans-serif;
}
h1,h2,h3,h4{
  margin: 0px;
  padding: 0px;
  font-family: 'Coolvetica Rg', sans-serif !important;
}
ul,ol{
  list-style: none;
}

button {
  text-transform: uppercase !important;
}

.shipment-slick .slick-track {
  margin-left: 0;
}

.shipment-slick .slick-slide {
  padding-right: 10px;
}

a{
  text-decoration: none;
}
