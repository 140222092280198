@import './mixin.scss';
@import './variables.scss';

.Breadcrum{
    width: 100%;
    padding-inline:1rem;
    text-transform: capitalize;
    margin-bottom: 1rem;
    margin-top:6rem;
    @include Optionflex(center,space-between);
    h5{
        @include PageTitle;
        color: $primary_color;
    }
    .Cost_Wrap{
        @include Optionflex(center,flex-end);
        h3{
           &.Price{
            color: $secondary_color;
           }
        }
    }
}

.shipCountries {
    width: 100%;
    padding-inline:1rem;
    text-transform: capitalize;
    margin-bottom: 1rem;

    .countryTopLabel {
        font-size: .8rem;
        font-weight: 700;
        margin: 2rem 0;
    }
    
    .addressContain {
        display: grid;
        grid-template-columns: repeat(10, minmax(0, 1fr));
        column-gap: 1rem;
        row-gap: 2rem;

        .countryWrap {
            display: flex;
            gap: .2rem;
            align-items: center;
            cursor: pointer;
            transition: all 500ms ease-in-out;

            &:hover {
                transform: scale(1.1);
            }

            p {
                font-size: .6rem;
                color: $gray_color;
                margin: 0;
            }
        }

        @include respond(mobile) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            // responsive code for mobile viewport i.e. 480px   
        }
    }
}

.Country_Modal_Wrapper{
    width:540px;
    height: 100%;
    box-sizing: border-box;
    padding-top: 2rem;
    .close_btn{
        position: absolute;
        right:1rem;
        top:1rem;
    }
    .modalContent {
        display: flex;
        gap: 1rem;

        img {
            width: 68px;
            height: 68px;
        }

        .countryRecipient {
            font-weight: 700;
            font-size: .8rem;
            margin-top: .4rem;
        }

        .countryName, .countryAddress {
            color: $gray_color;
            font-size: .7rem;
        }
    }
    .Tab_Wrapper{
        width: 100%;
        @include Optionflex(center,flex-start);
        gap:.3rem;
        .Tab{
            width: 160px;
            box-sizing: border-box;
            height:60px;
            border-radius: 3px;
            background-color:$sidebar_color;
            color: $dark;
            @include WalletTabText;
            @include flex;
            &.active_tab{
                background-color: $checkbox_border;
                color:$white_color;
            }
        }
    }
    .Details_Wrapper{
        width:100%;
        height: auto;
        border-radius: 3px;
        background-color:$sidebar_color;
        box-sizing: border-box;
        padding:3.5rem 0rem;
        @include flex;
        flex-direction: column;
        &.Amount{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding-inline:3rem;
            .Btn{
                @include ModalBtnDanger;
                @include ModalBtnText;
                background-color: $secondary_color;
                color: $white_color;
                cursor: pointer;
            }
            label {
                @include ModalAmount;
                color: $dark;
                &.error{
                    color: $btn_cancel;
                }
              }
    
              input {
                width: 100%;
                height: 45.02px;
                box-sizing: border-box;
                border-radius: 2.6235px;
                background-color: $white_color !important;
                outline: none;
                border: 1px solid transparent;
                padding: .5rem 1rem;
                // @include label;
                // color: $black_color;
                font-size: 13px;
                //text-transform: capitalize;
                margin:1rem 0rem;
                font-weight: normal;
    
                &::placeholder {
                //   @include inputText;
                //   color: $grey_color;
                  font-size: 13px;
                }
            }
        }
        .Top{
            width: 80%;
            margin-inline: auto;
        }
        .Bottom{
            width:80%;
            margin-inline: auto;
            margin-top: 2rem;
            @include Optionflex(center,space-between);
        }
        .Box{
            p{
                @include WalletTransferText;
                color: $checkbox_border;
            }
            h5{
              

            }
        }
    }
}