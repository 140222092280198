
// flex mixin
@mixin flex{
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin DMSan_Family{
    font-family: 'DM Sans', sans-serif;
}

@mixin Optionflex($center,$justify){
    display: flex;
    align-items: $center;
    justify-content: $justify;
}

@mixin Bg{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin LogoBg{
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
}

@mixin DangerBtn{
    @include flex;
    width: 100%;
    height: 44.58px;
    border-radius: 3.71471px;
}

@mixin DangerText{
font-weight: 700;
font-size: 14.8588px;
line-height: 30px;
}



// Button mixin

@mixin LandingBtnText{
font-weight: 500;
font-size: 17px;
line-height: 130%;
}


// Home Hero Text

@mixin HeroTitle{
font-weight: 700;
font-size: 60px;
line-height: 70px;
}

@mixin HeroTitleMobile{
font-weight: 700;
font-size: 30px;
line-height: 40px;
}

@mixin HeroText{
font-weight: 400;
font-size: 20px;
line-height: 150%;
}



// Warehouse Title

@mixin WarehouseTitle{
font-weight: 700;
font-size: 40px;
line-height: 47px;
}

@mixin WarehouseTitleMobile{
font-weight: 700;
font-size: 20px;
line-height: 27px;
}

@mixin WarehouseCardTitle{
font-weight: 500;
font-size: 24px;
line-height: 28px;
}

@mixin WarehouseCardText{
font-weight: 400;
font-size: 17px;
line-height: 150%;
}

// Middle qoute

@mixin MiddleQouteText{
font-weight: 500;
font-size: 24px;
line-height: 160%;
}

@mixin MiddleQouteTextMobile{
font-weight: 500;
font-size: 14px;
line-height: 100%;
}

@mixin MiddleQouteTinyTitle{
font-weight: 400;
font-size: 17px;
line-height: 100%;
}

@mixin MiddleQouteTinyText{
font-weight: 400;
font-size: 13px;
line-height: 15px;
}


// Service mixin

@mixin ServiceCartText{
font-weight: 400;
font-size: 16px;
line-height: 180%;
}

// Footer text

@mixin FooterTitle{
font-weight: 600;
font-size: 16px;
line-height: 30px;
}

@mixin FooterText{
font-weight: 500;
font-size: 12px;
line-height: 20px;
}

@mixin FooterTiny{
font-weight: 500;
font-size: 12px;
line-height: 137.5%;
}

// login mixin

@mixin LoginTiny{
font-weight: 400;
font-size: 20px;
line-height: 32px;
}

@mixin logInHelloText{
font-weight: 700;
font-size: 29.7176px;
line-height: 56px;
}

@mixin LoginText{
font-weight: 400;
font-size: 14.8588px;
line-height: 30px;
}

@mixin DescripText{
font-weight: 400;
font-size: 11.1441px;
line-height: 17px;
}


// Placeholder mixin

@mixin PlaceholderText{
font-weight: 400;
font-size: 12.3038px;
line-height: 21px;
}

// SideBar Mixin

@mixin AnchorText{
font-weight: 500;
font-size: 14px;
line-height: 23px;
}

@mixin PageTitle{
font-weight: 700;
font-size: 32px;
line-height: 42px;
}

@mixin PageTitleMobile{
font-weight: 700;
font-size: 20px;
line-height: 22px;
}

// Dashboard mixin

@mixin DashCardTopBalanceText{
font-weight: 400;
font-size: 14.142px;
line-height: 21px;
}

@mixin DashCardTopTitleText{
font-weight: 700;
font-size: 24.7485px;
line-height: 21px;
}

@mixin DashCardTopBottomText{
font-weight: 700;
font-size: 10.6065px;
line-height: 14px;
}

@mixin DashAnalysisTitle{
font-weight: 700;
font-size: 20px;
line-height: 26px;
letter-spacing: -0.02em;
}

@mixin RecentText{
font-weight: 700;
font-size: 14.0615px;
line-height: 18px;
}
@mixin ViewMore{
font-weight: 500;
font-size: 12.3038px;
line-height: 16px;
}
@mixin DashTableText{
font-weight: 400;
font-size: 13px;
line-height: 15px;
}
@mixin TableBtn{
width: 66px;
height: 23.89px;
border-radius: 3.80952px;
padding: 3.80952px 10px;
@include flex;
}


@mixin ProgressTitle{
font-weight: 700;
font-size: 10.55px;
line-height: 15px;
}

@mixin ProgressText{
font-weight: 700;
font-size: 8.78843px;
line-height: 11px;
}

@mixin ProgressCircleText{
font-weight: 700;
font-size: 8.78843px;
line-height: 11px;
letter-spacing: -0.02em;
}

@mixin BankTitle{
font-weight: 700;
font-size: 15.8192px;
line-height: 21px;
}

@mixin BankCardTitle{
font-weight: 700;
font-size: 17.5769px;
line-height: 23px;
}
@mixin BankCardText{
font-weight: 400;
font-size: 8.78843px;
line-height: 11px;
}

// all shipments mixin

@mixin selectedInputText{
font-weight: 400;
font-size: 10.494px;
line-height: 18px;
}

@mixin subShipmentsTitle{
font-weight: 700;
font-size: 16px;
line-height: 39px;
}

@mixin ShipmentsTableHeader{
font-weight: 700;
font-size: 12px;
line-height: 21px;
}

@mixin AllShipmentsTableText{
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
}

@mixin ShipmentsTableBodyTitle{
font-weight: 400;
font-size: 14px;
line-height: 21px;
}

@mixin ShipmentBtnText{
font-weight: 400;
font-size: 10px;
line-height: 12px;
}


// wallet mixin

@mixin WalletBtnText{
font-weight: 700;
font-size: 12px;
line-height: 24px;
}

@mixin WalletEmptyText{
font-weight: 400;
font-size: 14px;
line-height: 18px;
}

@mixin WalletEmptyTitle{
font-weight: 400;
font-size: 18px;
line-height: 39px;
}

@mixin WalletTabText{
font-weight: 700;
font-size: 10.5266px;
line-height: 18px;
}

@mixin WalletTransferText{
font-weight: 500;
font-size: 11.2978px;
line-height: 25px;
}

@mixin WalletTabHeader{
font-weight: 600;
font-size: 23.9155px;
line-height: 27px;
}

@mixin ModalAmountLabel{
font-weight: 700;
font-size: 10.5266px;
line-height: 18px;
}

@mixin ModalBtnDanger{
    width: 100%;
    height: 45.16px;
    border-radius: 2.63164px;
    @include flex;
}

@mixin ModalBtnText{
font-weight: 700;
font-size: 12px;
line-height: 18px;
}

// setting page mixin

@mixin SettingProfileLabel{
font-weight: 600;
font-size: 12px;
line-height: 21px;
}

@mixin FileText{
font-weight: 500;
font-size: 12px;
line-height: 21px;
}


@mixin settingModalPlaceholder{
font-weight: 400;
font-size: 10px;
line-height: 18px;
}

@mixin ModalAmount{
font-weight: 700;
font-size: 12px;
line-height: 18px;
}

@mixin ShipmentTabTitle{
font-weight: 700;
font-size: 16px;
line-height: 21px;
}

@mixin ShipmentTabText{
font-weight: 400;
font-size: 12px;
line-height: 20px;
}

@mixin TabTitle{
font-weight: 700;
font-size: 26px;
line-height: 39px;
}

@mixin CircleTabText{
font-weight: 400;
font-size: 10px;
line-height: 21px;
}

// form mixin

@mixin FormPlaceholder{
font-weight: 400;
font-size: 10.494px;
line-height: 18px;
}

@mixin settingModalPlaceholder{
font-weight: 400;
font-size: 10px;
line-height: 18px;
}

@mixin TabTitle{
font-weight: 700;
font-size: 26px;
line-height: 39px;
}

@mixin FormBtn{
    width: 100%;
    height: 43.08px;
    border-radius: 3px;
    @include flex;
    @include FormBtnText;
}

@mixin FormBtnText{
font-weight: 700;
font-size: 12px;
line-height: 24px;
}

@mixin formTiny{
font-weight: 400;
font-size: 12px;
line-height: 18px;
}

@mixin PackageSubText{
font-weight: 400;
font-size: 10.5266px;
line-height: 18px;
}

@mixin PackageSpanText{
font-weight: 400;
font-size: 12px;
line-height: 21px;
}


// payment method mixin

@mixin paymentTitle{
font-weight: 500;
font-size: 14px;
line-height: 21px;
}

@mixin BookShipmentTitle{
font-weight: 700;
font-size: 18px;
line-height: 21px;
}

@mixin BookShipmentText{
font-weight: 500;
font-size: 14px;
line-height: 20px;
}

@mixin BookShipmentBtnText{
font-weight: 500;
font-size: 14px;
line-height: 23px;
}

@mixin respond ($breakpoint) {

    // $breakpoint is simply a variable that can have several values

    @if $breakpoint==tablet {

        // here `laptop` is the value of $breakpoint
        // when call laptop, we mean the following piece of code        

    @media only screen and (max-width: 600px) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media only screen and (max-width: 480px) {
      @content;
    }
  }

  @if $breakpoint==tabletUp {
    @media only screen and (min-width: 481px) {
      @content;
    }
  }
}

