

@import './mixin.scss';
@import './variables.scss';

.AllShipment_Top{
    padding-inline:1rem;
    @include Optionflex(center,space-between);
     h3{
        @include subShipmentsTitle;
         color: $dark;
     }
     .Select_Box{
        @include flex;
        gap:.5rem;
        select{
            width: 180px;
            height: 35px;
            border-radius: 2.6235px;
            border: 1px solid $input_border;
            box-sizing: border-box;
            // padding:1rem .3rem;
            text-transform: capitalize;
            outline: none;
            @include selectedInputText;
            color:$light_gray;
            padding-left: 1rem;
        }
        input{
            width: 180px;
            height: 35px;
            border-radius: 2.6235px;
            border: 1px solid $input_border;
            box-sizing: border-box;
            // padding:1rem .3rem;
            text-transform: capitalize;
            outline: none;
            @include selectedInputText;
            color:$light_gray;
            padding-left: 1rem;
        }
        .Search_Box{
            background-color: $primary_color;
            @include flex;
            width: 34.99px;
            height: 35px;
            border-radius: 2.6235px;
        }   
    }
 }

 .AllShipment_Table_Wrapper{
    padding-inline: 1rem;

    .mobileCards {
        display: none;
    }
    table{
        width: 100%;
        border-collapse: collapse;
        margin-top: 1.5rem;
        thead{
            width: 100%;
            th{
                // width:200px;
                @include ShipmentsTableHeader;
                color:$checkbox_border;
                padding: 1rem 0rem;
                border-bottom: 1px solid $hero_shadow;
            }
        }
        tbody{
            width: 100%;
            tr{
                width: 100%;
                td{
                //    &:nth-of-type(odd){
                //     padding-right:5rem;
                //    }
                    border-bottom: 1px solid $hero_shadow;
                    .No_Data{
                        width: 100%;
                        text-align: center;
                        padding:4rem 0rem;
                        h5{
                            @include ShipmentsTableBodyTitle;
                            color: $dark; 
                        }
                    } 
                    .Box{
                        width:150px;
                        height:65px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        margin:.5rem 0rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        h5{
                            @include ShipmentsTableBodyTitle;
                            color: $dark; 
                        }
                        p{
                            @include AllShipmentsTableText;
                            color:$checkbox_border;
                            &:nth-of-type(2){
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                        .Btn{
                            width: 100px;
                            height: 26.71px;
                            border-radius: 1.44093px;
                            text-transform: uppercase;
                            cursor: pointer;
                            @include  ShipmentBtnText;
                            @include flex;
                            &.success{
                                background-color:$btn_bg_success;
                                color: $btn_success;
                            }
                            &.cancel{
                                background-color:$btn_bg_cancel;
                                color:$btn_cancel;
                            }
                            &.active{
                                background-color:$btn_bg_active;
                                color:$btn_active;
                            }
                            &.pending{
                                background-color:$btn_bg_pending;
                                color:$btn_pending;
                            }
                        }
                    } 
                }
            }
        }
    }
}

.ShipmentInfo {
    min-width: 700px;
    max-height: 400px;
    overflow-y: auto;

    .FormBtn{
        // @include FormBtn;
        color: $white_color;
        background-color:$btn_cancel;
        margin:1.5rem 0rem;
        cursor: pointer;
    }

    .BtnContain {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: .5rem;
        column-gap: 6rem;
    }

    .ShipmentSection {
        padding: 1rem 0;

        .ShipmentLabel {
            font-weight: 600;
            font-size: 16px;
        }

        .InfoLabel {
            font-weight: 500;
        }

        .ShipmentInfo {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));

            div {
                margin-bottom: .8rem;
            }

            .ShipRight {
                text-align: right;
            }

            .BtnShip{
                padding: 0 .5rem;
                height: 26.71px;
                font-size: 10px !important;
                border-radius: 1.44093px;
                text-transform: uppercase;
                flex-wrap: nowrap;
                cursor: pointer;
                font-size: 14px !important;
                &.success{
                    background-color:$btn_bg_success;
                    color: $btn_success;
                }
                &.cancel{
                    background-color:$btn_bg_cancel;
                    color:$btn_cancel;
                }
                &.active{
                    background-color:$btn_bg_active;
                    color:$btn_active;
                }
                &.pending{
                    background-color:$btn_bg_pending;
                    color:$btn_pending;
                }
            }
        }
    }

    .SectionDivide {
        border-bottom: 1px solid $input_border;
    }

    p {
        margin: 0;
        color: $light_gray;
        font-size: 14px;
    }
}




@media (max-width:768px){
    .AllShipment_Table_Wrapper{
        table{
            thead{
                th{
    
                }
            }
            tbody{
                tr{
                    td{
                        .Box{
                            width:115px;
                        
                        } 
                    }
                }
            }
        }
    }   
}



@media (max-width:400px){
    .AllShipment_Top{
        flex-direction: column;
        @include Optionflex(flex-start,center);
         .Select_Box{
            width: 100%;
           flex-direction: column;
           @include Optionflex(flex-start,center);
            select{
                width:100%;
            }
            input{
                width:100%;
            } 
        }
     }

     .AllShipment_Table_Wrapper{
        padding-inline: 1rem;

        .mobileCards {
            display: flex;
            flex-direction: column;
            row-gap: 1.4rem;
            margin-top: 2rem;

            & > div:nth-of-type(even){
                background-color:$home_bg;
            }

            .ShipCard {
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                padding: 1rem;

                display: flex;
                flex-direction: column;
                row-gap: .8rem;

                .BoxContain {
                    display: flex;
                    justify-content: space-between;
                }

                h5 {
                    font-size: 1rem;
                }

                p {
                    font-size: .8rem;
                    margin-bottom: .4rem;
                }

                .Btn{
                    width: 100px;
                    height: 26.71px;
                    border-radius: 1.44093px;
                    text-transform: uppercase;
                    cursor: pointer;
                    @include  ShipmentBtnText;
                    @include flex;
                    &.success{
                        background-color:$btn_bg_success;
                        color: $btn_success;
                    }
                    &.cancel{
                        background-color:$btn_bg_cancel;
                        color:$btn_cancel;
                    }
                    &.active{
                        background-color:$btn_bg_active;
                        color:$btn_active;
                    }
                    &.pending{
                        background-color:$btn_bg_pending;
                        color:$btn_pending;
                    }
                }
            }
        }
        table{
            display: none;
            thead{
                width: 100%;
                th{
                    width: 100%;
                    display: block;
                }
            }
            tbody{
                width: 100%;
                tr{
                    td{
                        width: 100%;
                        display: block;
                        .Box{
                            width:100%;
                        
                        } 
                    }
                }
            }
        }
    }

    .ShipmentInfo {
        width: 95vw;
        min-width: auto;
        // max-height: 400px;
        // overflow-y: auto;
    
        .ShipmentSection {
            padding: 1rem 0;
    
            .ShipmentLabel {
                font-weight: 600;
                font-size: 14px;
            }
        }

        .BtnContain {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    
        p {
            margin: 0;
            color: $light_gray;
            font-size: 12px;
        }
    }
}