@import './mixin.scss';
@import './variables.scss';


.Home_Btn{
width: 150px;
height:50px;
text-transform: uppercase;
cursor: pointer;
@include flex;
background-color: $primary_color;
a{
    @include LandingBtnText;
    color: $white_color;
}
}

.Nested_Btn{
    @include flex;
    @include FormBtnText;
    height: 44.58px;
    border-radius: 3.71471px;
    outline: none;
    border: none;
    background-color:$primary_color!important;
    color:$white_color;
    text-transform: capitalize;
    &:hover{
        opacity:.8;
    }
}

@media (max-width:400px){
    .Nested_Btn{
        width: 100%!important;
    }
}